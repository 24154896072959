import { useQuery } from "@apollo/client";
import { useState } from "react";

import { Box, Center, Stack } from "@suited/components";

import AssessmentDialog from "suited/components/CandidateHome/Assessment/AssessmentDialog/AssessmentDialog";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { timeouts } from "suited/constants/interaction-constants";

import SuitedAssessmentComponent from ".";
import { StyledBackgroundImg } from "../shared/img/StyledBackgroundImg";
import { ASSESSMENT_COMPLETE_ALT_TEXT } from "../shared/img/constants";
import { LegalCopy } from "../shared/typography/SuitedLegalCopy";
import { GET_USER_SURVEY_ANSWERS } from "./GraphQL/queries";
import { PsychometricAssessmentPageSkeleton } from "./SuitedAssessment.skeleton";
import { InstructionContent } from "./SuitedAssessmentLanding.style";
import { Completed, Instructions } from "./content/PsychometricAssessment";

export const PsychometricAssessment = () => {
  const [showAssessment, setShowAssessment] = useState(false);

  const { data, loading } = useQuery(GET_USER_SURVEY_ANSWERS);

  const completedPsychometricAssessment = data?.GetUserSurveyAnswers?.status === "complete";

  const handleClickBegin = () => {
    setShowAssessment(true);
  };

  if (loading) {
    return <PsychometricAssessmentPageSkeleton />;
  }

  if (completedPsychometricAssessment) {
    return (
      <Box overflow="auto">
        <Stack>
          <StyledBackgroundImg role="img" aria-label={ASSESSMENT_COMPLETE_ALT_TEXT} />
          <Center>
            <Box padding="xl">
              <Stack space="xl">
                <Completed />
              </Stack>
            </Box>
          </Center>
        </Stack>
      </Box>
    );
  }

  return (
    <Box padding="xl" overflow="auto">
      <Center>
        <InstructionContent>
          <AssessmentDialog
            show={showAssessment}
            onCancel={() => setShowAssessment(false)}
            renderAssessment={SuitedAssessmentComponent}
          />
          <Stack space="xl">
            <Instructions />
            <Box>
              <Stack>
                <LegalCopy>
                  THIS ASSESSMENT IS INTELLECTUAL PROPERTY CREATED AND ADMINISTERED BY SUITED. BY
                  PROCEEDING TO TAKE THIS ASSESSMENT, YOU AGREE TO NOT DISCLOSE, REDISTRIBUTE, OR
                  REPRODUCE THE CONTENTS, IN WHOLE OR IN PART, WITHOUT THE PRIOR EXPRESS WRITTEN
                  CONSENT OF SUITED.
                </LegalCopy>
              </Stack>
            </Box>
            <Center>
              <SuitedButton
                purpose="primary"
                onClick={handleClickBegin}
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                data-analytics-id="psych-assessment-start"
              >
                Begin
              </SuitedButton>
            </Center>
          </Stack>
        </InstructionContent>
      </Center>
    </Box>
  );
};
