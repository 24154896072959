import { ApolloProvider } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import { Outlet } from "@tanstack/react-router";
import { SkeletonTheme } from "react-loading-skeleton";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import AlertContainer from "suited/components/Alerts/AlertsContainer";
import ProfileCompleteProvider from "suited/components/CandidateHome/ProfileCompleteProvider";
import { setAuthToken } from "suited/components/Login";
import UnsupportedBrowser from "suited/components/shared/UnsupportedBrowser";

import "../node_modules/@fortawesome/fontawesome-pro-webfonts/css/fa-light.css";
import "../node_modules/@fortawesome/fontawesome-pro-webfonts/css/fa-regular.css";
import "../node_modules/@fortawesome/fontawesome-pro-webfonts/css/fa-solid.css";
import "../node_modules/@fortawesome/fontawesome-pro-webfonts/css/fontawesome.css";
import { apolloClient } from "./apolloClient";
import ErrorBoundary from "./components/ErrorBoundary";
import { FeatureFlags } from "./components/FeatureFlags/FeatureFlags";
import { store } from "./configureStore";
import "./fontAwesome";
import theme from "./theme";
import { ApolloClientInstance } from "./types";
import { getAuthToken } from "./util/authUtil";

export const AppContent = () => {
  const client = useApolloClient();
  return (
    <ProfileCompleteProvider client={client as ApolloClientInstance}>
      <Outlet />
      <FeatureFlags />
    </ProfileCompleteProvider>
  );
};

const App = () => {
  const token = getAuthToken();
  if (token) {
    store.dispatch(setAuthToken(token));
  }

  return (
    <ErrorBoundary client={apolloClient}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <SkeletonTheme baseColor="#d9e1e2" highlightColor="#e0e7e7">
            {window["unsupportedBrowser"] ? (
              <UnsupportedBrowser />
            ) : (
              <>
                <AlertContainer />
                <ApolloProvider client={apolloClient}>
                  <AppContent />
                </ApolloProvider>
              </>
            )}
          </SkeletonTheme>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
