import { useMutation } from "@apollo/client";
import { useNavigate } from "@tanstack/react-router";
import debounce from "lodash/debounce";
import { useCallback, useMemo, useState } from "react";

import { Box, Flex, HStack, Stack } from "@suited/components";
import { noop } from "@suited/utils";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import SuitedLink from "suited/components/shared/buttons/SuitedLink";
import { ResponsiveDialogModal } from "suited/components/shared/feedback/ResponsiveDialogModal/ResponsiveDialogModal";
import BasicDialogHeader from "suited/components/shared/layout/Dialogs/BasicDialogHeader";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { timeouts } from "suited/constants/interaction-constants";
import { routePaths } from "suited/constants/routePaths";
import { typography } from "suited/constants/style-constants";

import { CREATE_DEFAULT_ASSESSMENTS } from "../eca.queries";
import { DEBOUNCE_WAIT_TIME } from "./ReasonableAccomodationsModal.constants";

export const ReasonableAccommodationsModal = ({
  isOpen,
  onCancel,
  accommodationMultiplier = 1.0
}) => {
  const navigate = useNavigate();

  const [assessmentProvisionError, setAssessmentProvisionError] = useState(false);

  const [createAssessment, { loading }] = useMutation(CREATE_DEFAULT_ASSESSMENTS, {
    onCompleted: () => {
      onCancel();
      navigate({ to: routePaths.COGNITIVE_SKILLS_ASSESSMENT_APP });
    },
    onError: () => {
      setAssessmentProvisionError(true);
    }
  });

  // We have to memoize the debounced callback function to get past the ESLint
  // unknown dependencies error. See https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks.
  const debouncedHandler = useMemo(
    () => debounce(createAssessment, DEBOUNCE_WAIT_TIME),
    [createAssessment]
  );
  const startAssessment = useCallback(() => debouncedHandler(), [debouncedHandler]);

  const hasReasonableAccommodation = accommodationMultiplier !== 1;

  return (
    <ResponsiveDialogModal
      maxWidth={700}
      show={isOpen}
      onCancel={noop}
      closeDuration={timeouts.MODAL_DEFAULT_CLOSE_DURATION}
      iconButtonRow={<BasicDialogHeader onCancel={onCancel} />}
      shouldCloseOnEsc={false}
    >
      <Stack space="xl" width="85ch">
        <Box>
          <SuitedCopySubheadline noMargin data-testid="modal-heading">
            Before You Begin
          </SuitedCopySubheadline>
        </Box>
        <Box>
          <Stack>
            {!hasReasonableAccommodation ? (
              <Stack>
                <p style={{ textDecoration: "underline" }}>Reasonable Accommodations</p>
                <p>
                  This assessment contains problems to solve under timed conditions. If you need to
                  request a reasonable accommodation or will be using a screen reader to complete
                  this assessment, <strong>please refrain from starting</strong> and contact us at{" "}
                  <SuitedLink href="mailto:support@wellsuited.com?subject=Reasonable Accommodation Request for ECA">
                    support@wellsuited.com
                  </SuitedLink>
                  .
                </p>
              </Stack>
            ) : null}
            <Box>
              <p>
                This assessment is only compatible with the most recent versions of Chrome and
                Firefox. Please download the latest version before you begin.
              </p>
            </Box>

            {hasReasonableAccommodation ? (
              <p>
                You have been granted a reasonable accommodation of {accommodationMultiplier}× time.
                If this is not correct, <strong>do not start the assessment</strong> and please
                email us at{" "}
                <SuitedLink href="mailto:support@wellsuited.com?subject=Reasonable Accommodation Issue for ECA">
                  support@wellsuited.com
                </SuitedLink>
                .
              </p>
            ) : null}
            <p>
              You will only be able to complete the assessment once, so please make sure you have
              quiet, uninterrupted time before proceeding. Once you are ready, click below to begin.
            </p>
          </Stack>
        </Box>
        <Box>
          <Stack>
            <Box>
              <HStack justify="flex-end" space="sm">
                <Box>
                  <SuitedButton purpose="default" onClick={onCancel}>
                    Cancel
                  </SuitedButton>
                </Box>
                <Box>
                  <SuitedButton
                    purpose="primary"
                    disabled={loading}
                    onClick={startAssessment}
                    data-analytics-id="eca-assessment-start"
                  >
                    Start Assessment
                  </SuitedButton>
                </Box>
              </HStack>
            </Box>
            <Box>
              <Flex justify="flex-end">
                {loading && <p style={{ fontStyle: "italic" }}>Assessment loading...</p>}
                {assessmentProvisionError && (
                  <p style={{ fontStyle: "italic", color: typography.color.status.error }}>
                    There was an error loading the assessment. Try signing out and signing back in
                    to the app. If the issue persists, please reach out to support@wellsuited.com.
                  </p>
                )}
              </Flex>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </ResponsiveDialogModal>
  );
};
