import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import slugify from "slugify";

import { getAuthToken } from "./util/authUtil";

//
// Set up Apollo http link, middleware, and final composed link
//
//
// middleware/afterware - primary user
//
const userHttpLink = createHttpLink({
  uri: "/api/graphql"
});

export const createInMemoryCache = () => {
  return new InMemoryCache({
    dataIdFromObject: (object: any) => {
      // @WARNING ⚠️ - if you invoke an `object.{property}` that is not part of the bootstrap query, app authentication will stop working, with no explanatory error. -EH
      switch (object.__typename) {
        case "UserAnswer":
          return `suited-assessment-answer-${object.questionId}`;
        case "Question":
          return `suited-assessment-question-${object.id}`;
        case "UserSurveyAnswers":
          return "USER_SURVEY_ANSWERS";
        case "AssessmentOption":
          return `assessment-${slugify(object.name, {
            replacement: "_",
            lower: true
          })}`;
        case "Resume":
          return `resume-${object.id}`;
        case "User":
          return `candidate-${object.id}`;
        default:
          return object.id;
      }
    }
  });
};

const cache = createInMemoryCache();

// middleware/afterware - primary user
//
const headerAuthMiddleware = setContext(() => {
  const token = getAuthToken();
  return {
    headers: { Authorization: `Bearer ${token}` }
  };
});
export const apolloLink = ApolloLink.from([headerAuthMiddleware, userHttpLink]);

export const apolloClient = new ApolloClient({
  link: apolloLink,
  cache
});
