import { useNavigate } from "@tanstack/react-router";

import { Box, Center, HStack, Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { StyledFontAwesomeIcon } from "suited/components/shared/icons/StyledFontAwesomeIcon";
import SuitedCallout from "suited/components/shared/typography/SuitedCallout";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import { routePaths } from "suited/constants/routePaths";

// While somewhat similar to the "Assessment Completed" page, this view specifically
// appears in "Focus Mode" to let the user know that they've completed their assessment.
export const EndOfAssessmentPage = () => {
  const navigate = useNavigate();
  const redirectToECALandingPage = () => {
    navigate({ to: routePaths.COGNITIVE_SKILLS_ASSESSMENT });
  };

  return (
    <Box>
      <Center height="100vh">
        <Box>
          <Stack space="xl" maxWidth="100ch">
            <SuitedCopyHeadline noMargin>Assessment Completed</SuitedCopyHeadline>
            <HStack align="flex-start">
              <StyledFontAwesomeIcon size="3x" icon={["fal", "clipboard-check"]} />
              <Stack space="xl">
                <SuitedCallout noMargin>
                  Thank you for completing the Cognitive Skills Assessment, your responses have been
                  recorded and the results will be made available to recruiters soon! In the
                  meantime, make sure your profile is complete by clicking on the button below then
                  completing any remaining pages in the sidebar.
                </SuitedCallout>
              </Stack>
            </HStack>
            <Box>
              <SuitedButton
                purpose="primary"
                data-analytics-id="eca-assessment-finish"
                onClick={redirectToECALandingPage}
              >
                Close Assessment
              </SuitedButton>
            </Box>
          </Stack>
        </Box>
      </Center>
    </Box>
  );
};
