import * as Sentry from "@sentry/react";
import { Navigate, RouterProvider, createRouter } from "@tanstack/react-router";
import "raf/polyfill";
import { createRoot } from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import Modal from "react-modal";

import { DefaultErrorPage } from "./components/DefaultErrorPage";
import { AppConfig } from "./config";
import { routePaths } from "./constants/routePaths";
import "./index.css";
import { worker, workerConfigOptions } from "./mocks/browser";
import { seedDB } from "./mocks/seedDB";
import { routeTree } from "./routeTree.gen";

Sentry.init({
  dsn: "https://01939781220d045b01e3a7c82a05c489@o4507867901919232.ingest.us.sentry.io/4507867908734976",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (AppConfig.IS_DEV && AppConfig.VITE_ENABLE_MSW_MOCK_API === "true") {
  // @ts-ignore
  worker.start(workerConfigOptions);
  seedDB();
}

// for use of react-modal for accessibility:
// http://reactcommunity.org/react-modal/accessibility/
// and if we need to remove it at some point, pass this prop to the modal: ariaHideApp={false}
Modal.setAppElement("#root");

const router = createRouter({
  routeTree,
  defaultErrorComponent: ({ error }) => {
    if (error.message === "UNAUTHORIZED") {
      return <Navigate to={routePaths.LOGIN} />;
    }

    return <DefaultErrorPage />;
  }
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(<RouterProvider router={router} />);
