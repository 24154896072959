import { useLoaderData, useNavigate } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";
import axios from "axios";
import { useEffect } from "react";

import { setAuthToken } from "suited/components/Login";
import { storeUserId } from "suited/util/authUtil";

type SearchParams = {
  code: string;
  email: string;
  id: string;
};

export const Route = createFileRoute("/email-confirmed")({
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    if (!search.email || !search.id) {
      throw new Error("Missing required parameters");
    }

    return {
      code: (search.code as string) || "",
      email: search.email as string,
      id: search.id as string
    };
  },
  loader: async (context) => {
    const { code: invitationCode, email, id } = context.location.search as SearchParams;

    try {
      const res = await axios.post("auth/confirm-email-auth", {
        email,
        confirmationId: id
      });

      if (res.status === 200) {
        const { userId, token } = res.data;
        storeUserId(userId);
        setAuthToken(token);

        return {
          success: true,
          invitationCode,
          redirectTo: "/"
        };
      }
    } catch (err) {
      console.error("Error confirming email:", err);
      throw new Error("Failed to confirm email");
    }
  },
  component: () => {
    const navigate = useNavigate();
    const { redirectTo, success } = useLoaderData({ strict: false });

    useEffect(() => {
      if (success) {
        navigate({ to: redirectTo });
      }
    }, [success, navigate]);

    return null;
  }
});
