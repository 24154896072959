import { createFileRoute } from '@tanstack/react-router'
import { ChangeEmailErrorStates } from 'suited/components/CandidateHome/UserSettings/common'

import UserEmailChangeError from 'suited/components/CandidateHome/UserSettings/UserEmailChange/UserEmailChangeError'

export const Route = createFileRoute('/change-email-token-incorrect')({
  component: () => (
    <UserEmailChangeError error={ChangeEmailErrorStates.TOKENEXPIRED} />
  ),
})
