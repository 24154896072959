import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@tanstack/react-router";
import styled from "styled-components";

import SuitedIconButton from "suited/components/shared/buttons/SuitedIconButton";
import { animationCurves, colors, typography } from "suited/constants/style-constants";
import { hexToRgba } from "suited/util/cssColorTansforms";

import { Status } from "./NavItem.style";

export const ImgContainer = styled.div`
  display: grid;
  place-items: center;
  padding: 1.5rem 1rem;
  width: 100%;
`;

export const StyledHeaderImg = styled.img`
  width: 25px;
  height: 25px;
`;

interface IStatus {
  $completed: boolean;
}

export const StyledNavLink = styled(Link)<IStatus>`
  background-color: ${colors.background.dark.primary};
  text-decoration: none;
  transition: background-color 400ms ${animationCurves.easeOutQuart};
  font-size: 0.65rem;
  padding: 0;
  i {
    &.fas.fa-check-circle {
      color: ${typography.color.light.emphasis};
    }
  }
  ${Status} {
    transition: background-color 400ms ${animationCurves.easeOutQuart};
  }
  &:hover,
  &:focus {
    color: ${typography.color.light.emphasis};
    background-color: ${hexToRgba(colors.color5.tint1, 0.14)};
    cursor: pointer;
    ${Status} {
      background-color: ${(props) => (props.$completed ? colors.accent1.base : "transparent")};
    }
  }
  &:focus {
    outline: none;
  }
  &.link {
    display: block;
    color: ${typography.color.light.hint};
    &:hover {
      color: ${typography.color.light.base};
    }
  }
  &[aria-current="page"] {
    color: ${typography.color.light.emphasis};
    background-color: ${colors.accent1.base};
    ${Status} {
      background-color: ${(props) => (props.$completed ? colors.accent1.base : "transparent")};
    }
  }
`;

export const FeedbackLabelCopy = styled.div`
  ${typography.styles.nav};
  color: ${typography.color.light.base};
`;

export const FeedbackLabelHelperText = styled.div`
  color: ${typography.color.light.hint};
  font-family: ${typography.family.body};
  font-weight: ${typography.weight.body.regular};
  font-size: 12px;
  text-transform: none;
`;

export const FeedbackIcon = styled(FontAwesomeIcon)`
  color: ${typography.color.light.disabled};
`;

export const StyledSuitedIconButton = styled(SuitedIconButton)`
  margin: 0;
  color: ${typography.color.light.disabled};
  background-color: ${colors.background.dark.backdrop};
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    color: ${typography.color.light.emphasis};
    background-color: ${colors.background.dark.backdrop};
    transform: scale(1.1);
    border: 1px solid ${colors.tints.times2};
    box-shadow: inset 0px 0px 0px 2px ${colors.tints.times2};
  }
  &:focus:not([disabled]) {
    background-color: ${colors.background.dark.backdrop};
    border: 1px solid ${colors.color1.shade1};
    box-shadow: inset 0px 0px 0px 2px ${colors.color1.shade1};
  }
  &:active:not([disabled]),
  &:hover:not([disabled]):active,
  &:focus:not([disabled]):active {
    color: ${typography.color.light.emphasis};
    background-color: ${colors.background.dark.backdrop};
    border: 1px solid ${colors.color1.base};
    box-shadow: inset 0px 0px 0px 2px ${colors.color1.base};
  }
`;

export const NavItemContainer = styled.div`
  height: 100%;
  background: ${colors.background.dark.primary};
  width: 15rem;
`;
