import { createFileRoute, redirect } from "@tanstack/react-router";
import { RouteValues } from "shared/routes/routes.types";

import { CandidateHome } from "suited/components/CandidateHome/CandidateHome.component";
import { getAuthToken, isPublicRoute } from "suited/util/authUtil";

export const Route = createFileRoute("/_layout")({
  component: CandidateHome,
  beforeLoad: ({ location }) => {
    const token = getAuthToken();
    const isUnprotectedRoute = isPublicRoute(location.pathname as RouteValues);

    if (!token && !isUnprotectedRoute && location.pathname !== "/login") {
      throw redirect({
        to: "/login",
        search: {
          ...location.search,
          redirect: location.pathname
        }
      });
    }
  }
});
