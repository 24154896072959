import { useNavigate, useSearch } from "@tanstack/react-router";
import axios from "axios";
import owasp from "owasp-password-strength-test";
import React, { useCallback, useEffect, useReducer, useState } from "react";

import { Stack } from "@suited/components";

import { TIndustryContextId } from "suited/components/CandidateConfig/CandidateConfig.types";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import SuitedCheckbox from "suited/components/shared/inputs/SuitedCheckbox";
import { SuitedShowPasswordInput } from "suited/components/shared/inputs/SuitedShowPasswordInput";
import { debounce } from "suited/constants/interaction-constants";
import { useInitialMountEffect } from "suited/util/hooks";
import { readCookie } from "suited/util/utils";

import { IUserRegistrationProps } from "../../UserRegistration.component";
import IndustrySelect from "../IndustrySelect";
import { PasswordFeedback } from "./PasswordFeedback.component";
import { INVALID_INDUSTRY_CONTEXT_VALUE, initialState } from "./RegistrationForm.constants";
import { RegistrationWarningModal } from "./RegistrationForm.modal";
import { change, reducer } from "./RegistrationForm.reducer";
import {
  ErrorMessageContainer,
  ErrorMessageListContainer,
  ErrorSection,
  PasswordRequirementsHeading,
  RegisterFormColumn,
  RegisterFormFieldButtonWrapper,
  RegisterFormWrapper,
  RegisterPageExternalLink,
  RegisterTerms,
  StyledErrorMessage,
  StyledIcon,
  StyledSuitedValidatedTextInput,
  TermsContainer
} from "./RegistrationForm.style";
import { RegistrationPageCopy } from "./RegistrationForm.style";
import {
  OWASP_CONFIG,
  isPasswordMinimumLength,
  isPasswordUnderMaxLength,
  passwordHasLowercaseLetter,
  passwordHasNoMoreThanTwoCharactersRepeated,
  passwordHasNumber,
  passwordHasSpecialCharacter,
  passwordHasUppercaseLetter
} from "./RegistrationForm.utils";

// NOTE: Need to do this check for jest testing to work
if (owasp && typeof owasp.config === "function") {
  owasp.config(OWASP_CONFIG);
}

const RegistrationForm = (props: IUserRegistrationProps) => {
  const navigate = useNavigate();
  const search = useSearch({ strict: false });

  const { firstname, lastname, email } = props.identity;

  const companyNameParam = search?.companyName as string | undefined;
  const industryContextParam = search?.industryContext as string | undefined;

  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasInvitationCode = props.invitationCode.length > 0;
  const [values, dispatch] = useReducer(reducer, {
    ...initialState,
    firstName: firstname ? firstname : "",
    lastName: lastname ? lastname : "",
    email: email ? email : "",
    industryContext: props.industryContext
  });

  useInitialMountEffect(async () => {
    const isUserAuthenticated = readCookie("id_token");
    if (hasInvitationCode && isUserAuthenticated) {
      navigate({ to: `/join/${props.invitationCode}` });
    }

    // load industry contexts
    const response = await axios.get("/auth/industry-contexts");
    const validIndustryContexts = response.data?.data || [];
    dispatch(change("validIndustryContexts", validIndustryContexts));

    const hasInvalidIndustryContext = !validIndustryContexts
      .map((el) => el.id)
      .includes(values.industryContext as TIndustryContextId);
    if (hasInvalidIndustryContext) dispatch({ type: "SET_INVALID_INDUSTRY_CONTEXT" });
  });

  useEffect(() => {
    validateEmail();
  }, [values.email]); // eslint-disable-line

  useEffect(() => {
    switch (values.error) {
      case "Invalid firm type, please try again.":
        dispatch(change("industryContext", ""));
        break;
    }
  }, [values.error]);

  const handleChange = useCallback(
    (targetName: string, value: any) => dispatch(change(targetName, value)),
    []
  );

  const handleSelectIndustry = (value: string) => {
    handleChange("industryContext", value);
  };

  const handleToggleTerms = () => {
    handleChange("acceptTerms", !values.acceptTerms);
  };

  const handleBlurEmail = (value: any) => {
    if (value === "") handleChange("invalidEmail", true);
  };

  const validateEmail = () => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const check = re.test(String(values.email).toLowerCase().trim());

    if (check) {
      handleChange("invalidEmail", false);
    } else {
      handleChange("invalidEmail", true);
    }
  };

  const handlePasswordChange = (value: string) => {
    const password = value;
    if (password !== "") {
      setPasswordInvalid(false);
    }
    handleChange("validPassword", value);
  };

  const getPasswordErrorMessage = (value) => {
    if (value === "") return "This field is required.";
  };

  const handleClickRegister = () => {
    let error: string = "";

    if (values.invalidEmail) {
      error = "Please supply a valid email address.";
    } else if (values.firstName.trim().length === 0) {
      error = "Please enter your first name.";
    } else if (values.lastName.trim().length === 0) {
      error = "Please enter your last name.";
    } else if (values.validPassword === undefined || values.validPassword === "") {
      error = "Password is invalid.";
    } else if (values.acceptTerms === false) {
      error = "Please read and accept the Terms of Service and Privacy Policy.";
    }
    handleChange("error", error);
    if (error === "") {
      setIsModalOpen(true);
    }
  };

  const submitUserRegistrationInfo = () => {
    handleChange("isProcessing", true);

    return axios
      .post("/auth/register", {
        email: values.email.trim(),
        password: values.validPassword,
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        industryContext: values.industryContext,
        termsAndPrivacyAgreed: values.acceptTerms,
        invitationCode: props.invitationCode,
        autopilotSessionId: props.autopilotSessionId,
        utm: props.utm
      })
      .then((res: any) => {
        if (res.data.token) props.setAuthToken(res.data.token);
        handleChange("isProcessing", false);
        navigate({ to: res.data.redirectUrl });
      })
      .catch((err) => {
        handleChange("error", err.response?.data?.message);

        if (err.response) {
          if (err.response.status === 400 && err.response.data) {
            const data = err.response.data;
            if (data.message) {
              handleChange("error", data.message);
            }
            if (data.feedback && Array.isArray(data.feedback)) {
              handleChange("errorDetails", [...data.feedback]);
            }
          }
        } else {
          console.error(err);
        }
        handleChange("isProcessing", false);
        return false;
      });
  };

  const handlePasswordBlur = () => {
    if (values.validPassword === "") {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
  };

  const validationErrors = owasp.test(values.validPassword)?.failedTests;

  return (
    <React.Fragment>
      {values.hasInvalidIndustryContext ? (
        <IndustrySelect
          industryContextParam={industryContextParam}
          validIndustryContexts={values.validIndustryContexts}
          onChange={handleSelectIndustry}
          error={
            values.hasInvalidIndustryContext && !values.industryContext
              ? INVALID_INDUSTRY_CONTEXT_VALUE
              : undefined
          }
        />
      ) : null}
      <Stack space="sm" style={{ marginBottom: "1.75rem" }}>
        {companyNameParam ? (
          <>
            <RegistrationPageCopy>
              You are registering with an invitation from {companyNameParam}.
            </RegistrationPageCopy>

            <RegistrationPageCopy>
              If you already have a Suited account, please{" "}
              <RegisterPageExternalLink
                to="/login"
                search={{
                  code: props.invitationCode ? props.invitationCode : undefined,
                  utm_source: props.invitationCode ? "direct_invitation" : undefined
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                sign in
              </RegisterPageExternalLink>{" "}
              and the invitation will be associated with your Suited account.
            </RegistrationPageCopy>
            <RegistrationPageCopy>
              If you are new to Suited, please register using the email address associated with your
              invitation.
            </RegistrationPageCopy>
          </>
        ) : (
          <RegistrationPageCopy>
            We recommend that you register using the same email address that you use on employers'
            applications.
          </RegistrationPageCopy>
        )}
      </Stack>

      <RegisterFormWrapper data-testid="registration-form-wrapper">
        <RegisterFormColumn>
          <StyledSuitedValidatedTextInput
            inputKey={props.formKey}
            name="email"
            type="email"
            onInputChange={(value) => handleChange("email", value)}
            value={values.email}
            label="Email"
            autoComplete="email"
            required={true}
            invalid={values.invalidEmail}
            error={values.invalidEmail ? "Please enter a valid email address." : ""}
            onInputBlur={handleBlurEmail}
            debounceTime={debounce.validation}
            overDark={true}
          />
          <StyledSuitedValidatedTextInput
            name="firstname"
            type="text"
            onInputChange={(value) => handleChange("firstName", value)}
            value={values.firstName}
            label="First Name"
            autoComplete="given-name"
            required={true}
            debounceTime={debounce.validation}
            error="This field is required."
            overDark={true}
          />
          <StyledSuitedValidatedTextInput
            name="lastname"
            type="text"
            onInputChange={(value) => handleChange("lastName", value)}
            value={values.lastName}
            label="Last Name"
            autoComplete="family-name"
            required={true}
            debounceTime={debounce.validation}
            error="This field is required."
            overDark={true}
          />
          <SuitedShowPasswordInput
            name="password"
            value={values.validPassword}
            onInputChange={handlePasswordChange}
            label="Password"
            autoComplete="current-password"
            required={true}
            invalid={passwordInvalid}
            error={getPasswordErrorMessage(values.validPassword)}
            debounceTime={debounce.validation}
            overDark={true}
            onInputBlur={handlePasswordBlur}
            onKeyDown={(e) => {
              if (e.key === " ") {
                e.preventDefault();
              }
            }}
          />
          <Stack space="xxxs" style={{ marginTop: "1rem" }}>
            <PasswordRequirementsHeading>Password Requirements:</PasswordRequirementsHeading>
            <Stack space="xxs">
              <PasswordFeedback
                message="1 number"
                isChecked={passwordHasNumber(validationErrors)}
              />
              <PasswordFeedback
                message="1 special character"
                isChecked={passwordHasSpecialCharacter(validationErrors)}
              />
              <PasswordFeedback
                message="1 uppercase letter"
                isChecked={passwordHasUppercaseLetter(validationErrors)}
              />
              <PasswordFeedback
                message="1 lowercase letter"
                isChecked={passwordHasLowercaseLetter(validationErrors)}
              />
              <PasswordFeedback
                message="Between 10 and 20 characters"
                isChecked={
                  !!values.validPassword &&
                  isPasswordUnderMaxLength(validationErrors) &&
                  isPasswordMinimumLength(validationErrors)
                }
              />
              <PasswordFeedback
                message="No more than 2 consecutive characters"
                isChecked={
                  !!values.validPassword &&
                  passwordHasNoMoreThanTwoCharactersRepeated(validationErrors)
                }
              />
            </Stack>
          </Stack>
          <ErrorSection
            className="center-xs"
            visible={values.error && values.error.length > 0 ? true : false}
          >
            {values.error && (
              <ErrorMessageContainer>
                <StyledIcon name="exclamation-triangle" />
                <StyledErrorMessage
                  show={values.error ? true : false}
                  error={values.error}
                  validationFor="server-validation"
                />
              </ErrorMessageContainer>
            )}
          </ErrorSection>
          <ErrorSection
            className="center-xs"
            visible={values.errorDetails && values.errorDetails.length > 0}
          >
            {values.errorDetails && values.errorDetails.length > 0 ? (
              <ErrorMessageListContainer>
                {values.errorDetails.map((e, i) => {
                  return (
                    <ErrorMessageContainer key={i}>
                      <StyledIcon name="exclamation-triangle" />
                      <StyledErrorMessage show={e ? true : false} error={e} />
                    </ErrorMessageContainer>
                  );
                })}
              </ErrorMessageListContainer>
            ) : null}
          </ErrorSection>
        </RegisterFormColumn>
        <RegisterFormColumn>
          <TermsContainer>
            <SuitedCheckbox
              name="terms"
              onChange={handleToggleTerms}
              value={values.acceptTerms}
              checked={values.acceptTerms}
            >
              <RegisterTerms>
                <span>By registering I agree to the Suited </span>
                <RegisterPageExternalLink
                  to="http://wellsuited.com/terms"
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Terms Of Service
                </RegisterPageExternalLink>{" "}
                and{" "}
                <RegisterPageExternalLink
                  href="http://wellsuited.com/privacy"
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Privacy Policy
                </RegisterPageExternalLink>
                .
              </RegisterTerms>
            </SuitedCheckbox>
          </TermsContainer>
          <RegisterFormFieldButtonWrapper>
            <SuitedButton
              purpose="primary"
              overDark={true}
              disabled={
                !(
                  values.firstName &&
                  values.firstName.trim().length > 0 &&
                  values.lastName &&
                  values.lastName.trim().length > 0 &&
                  values.validPassword &&
                  validationErrors.length === 0 &&
                  values.industryContext &&
                  values.industryContext.length > 0 &&
                  !values.invalidEmail &&
                  values.acceptTerms
                ) || values.isProcessing
              }
              onClick={handleClickRegister}
            >
              {!values.isProcessing ? "Register" : "processing…"}
            </SuitedButton>
          </RegisterFormFieldButtonWrapper>
        </RegisterFormColumn>
      </RegisterFormWrapper>
      <RegistrationWarningModal
        isOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onSubmit={async () => {
          setIsModalOpen(false);
          submitUserRegistrationInfo();
        }}
      />
    </React.Fragment>
  );
};

export default RegistrationForm;
