import { useNavigate } from "@tanstack/react-router";
import React, { useState } from "react";

import { Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { SuitedValidatedTextInput } from "suited/components/shared/inputs/SuitedValidatedTextInput";
import { useAppDispatch } from "suited/util/hooks/reduxHooks";

import { debounce, timeouts } from "../../constants/interaction-constants";
import isValidEmail from "../../util/isValidEmail";
import AuthenticationTemplate from "../AuthenticationTemplate/AuthenticationTemplate";
import {
  InstructionsCopy,
  PasswordResetButtonRowCentered,
  PasswordResetFormWrapper
} from "./UserRequestPasswordReset.style";
import { requestUserPasswordReset } from "./reducers";

const UserPasswordReset = () => {
  const [email, setEmail] = useState("");
  const [resetRequested, setResetRequested] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const submit = (event?: React.FormEvent) => {
    if (event && event.preventDefault) event.preventDefault();
    if (email && isValidEmail(email)) {
      dispatch(requestUserPasswordReset(encodeURIComponent(email)));
      setResetRequested(true);
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
  };

  const handleChangeEmail = (value: string) => {
    setEmail(value);
    setInvalidEmail(!isValidEmail(value));
  };

  const handleClickSignIn = () => {
    navigate({ to: "/login" });
  };

  return (
    <AuthenticationTemplate
      headline="Forgot Your Password?"
      ctaText="Sign In"
      ctaOnClick={handleClickSignIn}
    >
      <PasswordResetFormWrapper onSubmit={submit}>
        {resetRequested ? (
          <Stack>
            <InstructionsCopy className="end">
              An email has been sent with instructions for resetting your password. If you don't
              receive an email in a few minutes, please check your spam folder.
            </InstructionsCopy>
            <PasswordResetButtonRowCentered>
              <SuitedButton
                purpose="primary"
                overDark={true}
                onClick={handleClickSignIn}
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
              >
                Sign In
              </SuitedButton>
            </PasswordResetButtonRowCentered>
          </Stack>
        ) : (
          <Stack>
            <InstructionsCopy>
              Please submit your email and we'll send you a link to reset your password.
            </InstructionsCopy>
            <SuitedValidatedTextInput
              name="email"
              type="email"
              onInputChange={handleChangeEmail}
              value={email}
              label="Email"
              autoComplete="email"
              required={true}
              invalid={!email || invalidEmail}
              error={invalidEmail ? "Please enter a valid email." : undefined}
              debounceTime={debounce.validation}
              overDark={true}
            />
            <PasswordResetButtonRowCentered>
              <SuitedButton
                purpose="primary"
                overDark={true}
                type="submit"
                onClick={submit}
                disabled={!email || invalidEmail}
                delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
              >
                Submit
              </SuitedButton>
            </PasswordResetButtonRowCentered>
          </Stack>
        )}
      </PasswordResetFormWrapper>
    </AuthenticationTemplate>
  );
};

export default UserPasswordReset;
