import { useRouter } from "@tanstack/react-router";
import Skeleton from "react-loading-skeleton";

import { Box, Flex } from "@suited/components";

import { NavIcon, NavLabel } from "./NavItem.style";

interface Props {
  $completed: boolean;
  label: string;
  className?: string;
  locked?: boolean;
  isLoading?: boolean;
  placeholderCount?: number;
  url?: string;
}

const NavItem = ({
  locked,
  $completed,
  label,
  className,
  isLoading,
  url,
  placeholderCount = 1
}: Props) => {
  const router = useRouter();

  // If status is complete, render bold icon.
  const prefix = $completed ? "fas" : "fal";
  const icon = locked ? "lock-alt" : "check-circle";

  const isSelected = url === router.state.location.pathname;
  const skeletonBaseColor = isSelected ? "#444" : "#202020";
  const skeletonHighlightColor = isSelected ? "#202020" : "#444";

  return (
    <Box className={className}>
      <Flex justify="space-between">
        <Box padding="lg">
          <NavLabel>
            {isLoading ? (
              <Box>
                <Skeleton
                  width="9rem"
                  baseColor={skeletonBaseColor}
                  highlightColor={skeletonHighlightColor}
                  count={placeholderCount}
                />
              </Box>
            ) : (
              <span>{label}</span>
            )}
          </NavLabel>
        </Box>
        <Flex flex="0 0 72px" justify="center" align="center" alignSelf="stretch">
          {isLoading ? (
            <Box>
              <Skeleton
                width={16}
                height={16}
                circle
                baseColor={skeletonBaseColor}
                highlightColor={skeletonHighlightColor}
              />
            </Box>
          ) : (
            <NavIcon
              data-testid="nav-item-icon"
              size="lg"
              icon={[prefix, icon]}
              $isCompleted={$completed}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default NavItem;
