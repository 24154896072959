import styled from "styled-components";

export const Badge = styled.div`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 12px;
  display: inline-grid;
  grid-column-gap: 8px;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 16px;
  border: 1px solid #ae7626;
  background: #d39237;
  cursor: default;
  p {
    color: #ffff;
    font-size: 11px;
    line-height: normal;
  }
`;
