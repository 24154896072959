import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/home/connection-manager")({
  beforeLoad: ({ location }) => {
    throw redirect({
      to: "/connection-manager",
      search: {
        ...location.search
      }
    });
  },
  component: () => null
});
