import { useNavigate, useSearch } from "@tanstack/react-router";
import owasp from "owasp-password-strength-test";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { SuitedValidatedTextInput } from "suited/components/shared/inputs/SuitedValidatedTextInput";

import { debounce, timeouts } from "../../constants/interaction-constants";
import { typography } from "../../constants/style-constants";
import { getIsStorybook } from "../../shared/config/config.slice";
import { useAppSelector } from "../../util/hooks/reduxHooks";
import AuthenticationTemplate from "../AuthenticationTemplate/AuthenticationTemplate";
import SuitedLink from "../shared/buttons/SuitedLink";
import ButtonRowCentered from "../shared/layout/ButtonRowCentered";
import { Error, ErrorSection, PasswordResetFormWrapper } from "./UserPasswordReset.style";
import { InstructionsCopy } from "./UserRequestPasswordReset.style";
import { clearErrors, getPasswordResetById, resetUserPassword, stateKey } from "./reducers";

interface Props {
  resetUserPassword({ password, resetId }): void;
  getPasswordResetById(id): void;
  clearErrors(): void;
  role: string;
  resetStatus: string;
  errorDetails: string[];
  errors: string[];
  passwordResetSuccess: boolean;
}

const UserPasswordReset = ({
  resetUserPassword,
  getPasswordResetById,
  clearErrors,
  resetStatus,
  passwordResetSuccess,
  errors,
  errorDetails
}: Props) => {
  const search = useSearch({ strict: false });
  const isStorybook = useAppSelector(getIsStorybook);

  const passwordResetId = search.passwordResetId;
  const [validPassword, setValidPassword] = useState<string>("");
  const [navigateToLogin, setNavigateToLogin] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordStrengthResults, setPasswordStrengthResults] = useState<any>({});
  const [passwordErrorList, setPasswordErrorList] = useState<string[]>([]);
  const [touched, setTouched] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (passwordResetId) {
      if (isStorybook) return;
      getPasswordResetById(passwordResetId);
    } else {
      console.error("Could not find password reset id in url; cannot continue");
    }
  }, [passwordResetId, getPasswordResetById]);

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    if (validPassword !== "") {
      resetUserPassword({
        password: validPassword,
        resetId: passwordResetId
      });
    } else {
      clearErrors();
    }
  };

  const handleChangePassword = (value: string) => {
    const passwordResults = owasp.test(value);
    let passwordErrorList: string[] = [];
    if (passwordResults.errors) {
      passwordErrorList = [...passwordErrorList, ...passwordResults.errors];
    }
    setPassword(value);
    setPasswordStrengthResults(passwordResults);
    setPasswordErrorList(passwordErrorList);
    setValidPassword(passwordResults.strong ? value : "");
  };

  const handlePasswordFocus = () => {
    setTouched(true);
  };

  const handleClickSignIn = () => {
    setNavigateToLogin(true);
  };

  if (navigateToLogin) {
    navigate({ to: "/login" });
    return;
  }

  return (
    <AuthenticationTemplate
      headline="Reset Password"
      ctaText="Sign In"
      ctaOnClick={handleClickSignIn}
      children={
        <PasswordResetFormWrapper onSubmit={submit}>
          {passwordResetSuccess === true ? (
            <Stack>
              <InstructionsCopy className="end">Your password has been reset.</InstructionsCopy>
              <ButtonRowCentered>
                <SuitedLink to={"/"} tooltip="To Login Page">
                  Click to proceed to the login page.
                </SuitedLink>
              </ButtonRowCentered>
            </Stack>
          ) : (
            <div>
              {resetStatus === "ACTIVE" ? (
                <>
                  <SuitedValidatedTextInput
                    name="password"
                    type="password"
                    onInputChange={handleChangePassword}
                    value={password}
                    label="New Password"
                    autoComplete="new-password"
                    required={true}
                    invalid={
                      touched &&
                      !(passwordStrengthResults as Record<string, any>).strong &&
                      passwordErrorList.length > 0
                    }
                    error={(passwordErrorList && passwordErrorList[0]) || "This field is required."}
                    debounceTime={debounce.typeAhead}
                    overDark={true}
                    onInputFocus={handlePasswordFocus}
                  />

                  {errors && errors.length > 0 ? (
                    <ErrorSection>
                      <div
                        style={{
                          marginBottom: "10px"
                        }}
                      >
                        {errors.map((e, i) => (
                          <Error key={i}>{e}</Error>
                        ))}
                      </div>
                      {errorDetails && errorDetails.length > 0 ? (
                        <div
                          style={{
                            color: `${typography.color.light.disabled}`,
                            fontSize: "1.5em",
                            fontWeight: "bold"
                          }}
                        >
                          Suggestions:
                        </div>
                      ) : null}
                      <div>
                        {errorDetails.map((e, i) => (
                          <Error key={i}>{e}</Error>
                        ))}
                      </div>
                    </ErrorSection>
                  ) : null}
                  <ButtonRowCentered>
                    <SuitedButton
                      purpose="primary"
                      overDark={true}
                      type="submit"
                      onClick={submit}
                      delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                      disabled={!validPassword}
                      title="Submit"
                    >
                      Submit
                    </SuitedButton>
                  </ButtonRowCentered>
                </>
              ) : (
                <Stack>
                  <InstructionsCopy className="end">
                    Please enter a valid email address.
                  </InstructionsCopy>
                  <ButtonRowCentered>
                    <SuitedLink
                      to="/user-request-password-reset"
                      tooltip="Send a new password reset link"
                    >
                      Would you like another one?
                    </SuitedLink>
                  </ButtonRowCentered>
                </Stack>
              )}
            </div>
          )}
        </PasswordResetFormWrapper>
      }
    />
  );
};

const mapStateToProps = (state) => ({
  role: state[stateKey].role,
  resetStatus: state[stateKey].resetStatus,
  passwordResetSuccess: state[stateKey].passwordResetSuccess,
  errorDetails: state[stateKey].errorDetails,
  errors: state[stateKey].errors
});

const mapDispatchToProps = (dispatch) => ({
  resetUserPassword: ({ password, resetId }) => {
    dispatch(resetUserPassword({ password, resetId }));
  },
  getPasswordResetById: (id: string) => {
    dispatch(getPasswordResetById(id));
  },
  clearErrors: () => {
    dispatch(clearErrors());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPasswordReset);
