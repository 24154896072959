import { Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { colors } from "suited/constants/style-constants";

import { timeouts } from "../constants/interaction-constants";
import AuthenticationTemplate from "./AuthenticationTemplate/AuthenticationTemplate";
import SuitedLink from "./shared/buttons/SuitedLink";
import ButtonRowCentered from "./shared/layout/ButtonRowCentered";

export const DefaultErrorPage = () => {
  const reloadPage = () => {
    window.location.reload();
  };
  const sendFeedback = () => {
    window.open("mailto:support@wellsuited.com", "_blank");
  };
  return (
    <AuthenticationTemplate headline="We've Encountered an Error">
      <Stack>
        <div>
          <p style={{ color: colors.white }}>
            An unexpected error has occurred. We apologize for the inconvenience.
          </p>
          <p style={{ color: colors.white }}>
            Try refreshing the page to resolve this issue. If the problem persists, please{" "}
            <SuitedLink onClick={sendFeedback} tooltip="Contact Support">
              contact our support team
            </SuitedLink>{" "}
            and describe the steps that led to this error.
          </p>
        </div>
        <div>
          <ButtonRowCentered>
            <SuitedButton
              purpose="primary"
              onClick={reloadPage}
              title="Refresh Page"
              delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
            >
              Refresh Page
            </SuitedButton>
          </ButtonRowCentered>
        </div>
      </Stack>
    </AuthenticationTemplate>
  );
};
