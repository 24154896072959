import axios from "axios";
import { storeAuthToken, deleteStoredCredentials, storeUserId } from "../../util/authUtil";
import { UTMParams } from "./Login.types";

export const stateKey = "Login";
export const ACTION_AUTH_LOGIN_PENDING = "ACTION_AUTH_LOGIN_PENDING";
export const ACTION_AUTH_LOGIN_SUCCESS = "ACTION_AUTH_LOGIN_SUCCESS";
export const ACTION_AUTH_LOGIN_FAILED = "ACTION_AUTH_LOGIN_FAILED";
export const ACTION_AUTH_LOGOUT = "ACTION_AUTH_LOGOUT";
export const ACTION_AUTH_NOT_CONFIRMED = "ACTION_AUTH_NOT_CONFIRMED";
export const ACTION_AUTH_SET_TOKEN = "ACTION_AUTH_SET_TOKEN";
export const ACTION_AUTH_UNSET_TOKEN = "ACTION_AUTH_DELETE_TOKEN";
export const ACTION_SET_INVITATION_CODE = "ACTION_SET_INVITATION_CODE";

const initialState = {
  token: "",
  frontUserHash: "",
  notConfirmed: false, // Default so error doesn't appear on pageload
  notConfirmedEmail: "",
  isAuthenticated: false,
  isAuthenticating: false,
  authenticationFailed: false,
  requestId: "",
  invitationCode: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_AUTH_LOGIN_PENDING:
      return {
        ...initialState,
        isAuthenticating: true,
        authenticationFailed: false
      };
    case ACTION_AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        authenticationFailed: false
      };
    case ACTION_AUTH_SET_TOKEN:
      return { ...state, token: action.token, isAuthenticated: true };
    case ACTION_AUTH_UNSET_TOKEN:
      return { ...state, token: "" };
    case ACTION_AUTH_LOGIN_FAILED:
      return { ...initialState, authenticationFailed: true };
    case ACTION_AUTH_NOT_CONFIRMED:
      return {
        ...initialState,
        notConfirmed: true,
        notConfirmedEmail: action.email,
        requestId: action.requestId
      };
    case ACTION_AUTH_LOGOUT:
      destroyAuthToken();
      return { ...initialState, invitationCode: "" };
    case ACTION_SET_INVITATION_CODE:
      return { ...initialState, invitationCode: action.invitationCode };
    default:
      return state;
  }
};

//
// ACTION CREATORS
//

export const login = (email: string, password: string, invitationCode: string, utm: UTMParams) => {
  return (dispatch) => {
    dispatch({ type: ACTION_AUTH_LOGIN_PENDING });
    return axios
      .post("/auth/login", { email, password, invitationCode, utm })
      .then((res) => {
        // if we don't have the data property, the rest of this function will fail badly. However, we let that
        // "just happen" so the console sees the errors of that and we handle the error as expected in the
        // handler below. Not sure this is the "best way" but for now it should be good enough (given this shouldn't
        // be something we see very often, if ever).
        if (!res.data) console.warn("login response missing data");

        // store the auth details for reference post app reload
        const { userId, token } = res.data;

        if (!token) console.warn("Missing token data");
        if (invitationCode) {
          dispatch(setInvitationCode(invitationCode));
        }

        // set heap analytics user id
        // @ts-ignore (window.heap loaded in index.html)
        if (userId && window.heap) {
          // @ts-ignore (sits on the window object from index.html script load)
          window.heap.identify(userId);
        }

        // store the userId in cookie for initializing ReactGA on app reload
        storeUserId(userId);
        // authenticate
        dispatch(setAuthToken(token));

        return dispatch({
          type: ACTION_AUTH_LOGIN_SUCCESS,
          requestId: res.data.requestId
        });
      })
      .catch((err) => {
        if (err.response && err.response.data.message === "not confirmed") {
          return dispatch({
            type: ACTION_AUTH_NOT_CONFIRMED,
            email: err.response.data.email,
            requestId: err.response.data.requestId
          });
        }
        return dispatch({ type: ACTION_AUTH_LOGIN_FAILED });
      });
  };
};

const setInvitationCode = (invitationCode: string) => {
  return {
    type: ACTION_SET_INVITATION_CODE,
    invitationCode
  };
};

export const setAuthToken = (token: string) => {
  storeAuthToken(token);
  return {
    type: ACTION_AUTH_SET_TOKEN,
    token
  };
};

const destroyAuthToken = () => {
  deleteStoredCredentials();
  // clear the analytics userId
  return {
    type: ACTION_AUTH_UNSET_TOKEN
  };
};

export default reducer;
