// @ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Accordion from "@radix-ui/react-accordion";
import { forwardRef } from "react";
import styled from "styled-components";

import { Box, Stack, Text } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";

import { StyledSuitedCopy } from "../ConnectionManager/shared/Main.style";

const StyledStack = styled(Stack)`
  justify-content: center;
  align-items: center;
`;

const Header = styled(SuitedCopySubheadline)`
  margin-bottom: 0;
  text-align: center;
`;

const AccordionWrapper = styled.div`
  /* reset */
  button,
  h3 {
    all: unset;
  }

  .AccordionRoot {
    border-radius: 6px;
    padding: 0 20px;
  }

  .AccordionItem {
  }

  .AccordionItem:first-child {
    margin-top: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .AccordionItem:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .AccordionItem:focus-within {
    position: relative;
    z-index: 1;
  }

  .AccordionHeader {
    display: flex;
  }

  .AccordionTrigger {
    font-family: inherit;
    background-color: transparent;
    padding: 0 20px;
    height: 45px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    line-height: 1;
    color: #11181f;
    background-color: white;
  }

  .AccordionTrigger:hover {
    background-color: #f8f7f7;
  }

  .AccordionContent {
    overflow: hidden;
    font-size: 15px;
    color: #11181f;
    background-color: white;
  }
  .AccordionContent[data-state="open"] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  .AccordionContent[data-state="closed"] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  .AccordionContentText {
    padding: 15px 20px;
  }

  .AccordionChevron {
    color: #11181f;
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  .AccordionTrigger[data-state="open"] > .AccordionChevron {
    transform: rotate(180deg);
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`;

const AccordionElement = () => (
  <AccordionWrapper>
    <Accordion.Root className="AccordionRoot" type="single" defaultValue="item-1" collapsible>
      <Accordion.Item className="AccordionItem" value="item-1">
        <AccordionTrigger>Can I create more than one account with Suited?</AccordionTrigger>
        <AccordionContent>
          <div className="AccordionContentText">
            Each candidate only needs one account on our platform, which applies across all job
            applications on Suited. Creating multiple accounts is prohibited, as it compromises the
            integrity and fairness of our services.
          </div>
        </AccordionContent>
      </Accordion.Item>

      <Accordion.Item className="AccordionItem" value="item-2">
        <AccordionTrigger>
          Can I retake my assessments if I want to try to improve my results?
        </AccordionTrigger>
        <AccordionContent>
          <div className="AccordionContentText">
            Assessments can only be taken once, but, as they are meant to capture your working
            style, behavioral traits and values, and certain innate cognitive skills, it’s not
            something you need to study for, nor would re-taking it necessarily improve your result,
            as it is all relative to the firm you are applying to. Attempting to retake assessments
            could lead to account restrictions or notifications to our partner firms, as it is
            considered a violation of our terms of service.
          </div>
        </AccordionContent>
      </Accordion.Item>

      <Accordion.Item className="AccordionItem" value="item-3">
        <AccordionTrigger>
          Is it possible to obtain a copy of my assessment results?
        </AccordionTrigger>
        <Accordion.Content className="AccordionContent">
          <div className="AccordionContentText">
            Your results are a combination of both your answers from the assessment, and how they
            compare to a firm’s model, which means it can vary across multiple firms, and won’t make
            it meaningful out of context. Since your results depend on the firm's data, we are not
            able to share it with you.
          </div>
        </Accordion.Content>
      </Accordion.Item>

      <Accordion.Item className="AccordionItem" value="item-4">
        <AccordionTrigger>
          If I already connected with a firm, do I need to re-connect for future job applications?
        </AccordionTrigger>
        <Accordion.Content className="AccordionContent">
          <div className="AccordionContentText">
            You only need to connect to a firm once on our platform, and that connection will remain
            valid for any future applications to the same firm.
          </div>
        </Accordion.Content>
      </Accordion.Item>

      <Accordion.Item className="AccordionItem" value="item-5">
        <AccordionTrigger>Can Suited extend my job application deadline?</AccordionTrigger>
        <Accordion.Content className="AccordionContent">
          <div className="AccordionContentText">
            Suited does not set or manage application deadlines on behalf of our partners. You will
            need to contact the recruiting team at the firm directly regarding deadlines and
            extensions.
          </div>
        </Accordion.Content>
      </Accordion.Item>

      <Accordion.Item className="AccordionItem" value="item-6">
        <AccordionTrigger>How can I share my results with firms?</AccordionTrigger>
        <Accordion.Content className="AccordionContent">
          <div className="AccordionContentText">
            <Stack>
              <div>
                You can choose to make your profile and your results public or private, which will
                determine which firms your results are shared with.
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Public Candidates:</span>
                If you are a public candidate, your results are shared with all firms on our
                platform. You can verify your public candidate status by checking if the “Share my
                Suited profile” option under privacy settings is set to “Publicly to all recruiters
                and companies.”
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Private Candidates:</span> If you are a private
                candidate and have accepted an invitation from a firm, only those firms with which
                you have accepted an invitation will see your results. You can confirm your private
                candidate status if the “Share my Suited profile” option under privacy settings is
                set to “To the companies that invited me.”
              </div>
              <div>
                Your profile will remain available to a firm so long as you still have an active
                connection and you can withdraw that connection at any time.
              </div>
            </Stack>
          </div>
        </Accordion.Content>
      </Accordion.Item>

      <Accordion.Item className="AccordionItem" value="item-7">
        <AccordionTrigger>What reasonable accommodations does Suited provide?</AccordionTrigger>
        <Accordion.Content className="AccordionContent">
          <Stack className="AccordionContentText">
            <div>
              We provide accommodations in the form of additional time to complete the Cognitive
              Skills Assessment (CSA), which has four sections with various time limits and breaks
              between. The Behavioral Assessment is untimed and can be completed at your own pace.
              We do require additional documentation to verify and enable the reasonable
              accommodation, such as official documentation (e.g., from a college/university, a
              standardized test, or a medical professional) that describes the specific
              accommodation that you are requesting. Please note that we do not require
              documentation on the underlying condition, only the accommodation that is required.
            </div>
            <div>
              Your accommodation status is not visible to recruiters and we do not disclose how long
              it took for candidates to complete the assessment. Any communications or documents
              between candidates and Suited regarding reasonable accommodations is strictly
              confidential and used only by our assessment team in determining eligibility for
              accommodation. We do not share any information with our partner firms and they will
              not be aware that a request was even made.
            </div>
            <div>
              Our application and assessments were designed to be compatible with commonly used
              screen readers. Should you require use of a screen reader, please contact our
              Assessment team via the button below for more information on optimizing screen reader
              settings and receiving the appropriate time extensions for the Cognitive Skills
              Assessment.
            </div>
          </Stack>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  </AccordionWrapper>
);

const AccordionTrigger = forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className="AccordionHeader">
    <Accordion.Trigger className="AccordionTrigger" {...props} ref={forwardedRef}>
      {children}
      <FontAwesomeIcon icon={["fal", "chevron-down"]} className="AccordionChevron" aria-hidden />
    </Accordion.Trigger>
  </Accordion.Header>
));

const AccordionContent = forwardRef(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content className="AccordionContent" {...props} ref={forwardedRef}>
    <div className="AccordionContentText">{children}</div>
  </Accordion.Content>
));

export const Faq = () => {
  return (
    <Box height="100%" width="100%" overflow="auto">
      <StyledStack
        space="xl"
        style={{ width: "min(85ch, calc(100% - 2rem))", margin: "4rem auto" }}
      >
        <Stack space="lg">
          <Header>Frequently Asked Questions</Header>
          <Text align="center">
            Please look for your question (and answer!) here. If you don't see an answer to your
            question, please contact us below.
          </Text>
        </Stack>
        <Box>
          <AccordionElement />
        </Box>
        <Box>
          <Stack space="lg">
            <Text align="center">
              You can also contact us if you'd like to request a reasonable accommodation here.
            </Text>
            <Box style={{ marginLeft: "auto", marginRight: "auto" }}>
              <SuitedButton
                onClick={() => {
                  window.open("mailto:support@wellsuited.com?subject=Email Support", "_blank");
                }}
              >
                Contact us
              </SuitedButton>
            </Box>
          </Stack>
        </Box>
      </StyledStack>
    </Box>
  );
};
