import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";

import { Stack } from "@suited/components";

import { colors } from "suited/constants/style-constants";

import AuthenticationTemplate from "../../../AuthenticationTemplate/AuthenticationTemplate";
import SuitedLink from "../../../shared/buttons/SuitedLink";
import { ChangeEmailErrorStates } from "../common";

type Props = {
  error: string;
};

const UserEmailChangeError = (props: Props) => {
  const navigate = useNavigate();
  const [navigateToLogin, setNavigateToLogin] = useState<boolean>(false);

  const handleClickSignIn = () => {
    setNavigateToLogin(true);
  };

  if (navigateToLogin) {
    navigate({ to: "/login" });
    return null;
  }

  const { error } = props;

  const paragraphStyles = { color: colors.white };

  const errorMessage = (errorState: string) => {
    switch (errorState) {
      case ChangeEmailErrorStates.TOKENEXPIRED:
        return (
          <Stack>
            <p style={paragraphStyles}>
              The token associated with this email change request has expired.
            </p>
            <p style={paragraphStyles}>
              Please proceed to{" "}
              <SuitedLink href="/home/user-settings" tooltip="User Settings">
                User Settings
              </SuitedLink>{" "}
              to log in and initiate a new email change.
            </p>
          </Stack>
        );
      case ChangeEmailErrorStates.TOKENINCORRECT:
        return (
          <Stack>
            <p style={paragraphStyles}>
              The token associated with this email change request is incorrect.
            </p>
            <p style={paragraphStyles}>
              Please try clicking the link to confirm your new email again. If you copy and pasted
              the link into your browser, please make sure there are no additional letters or
              numbers when you paste the URL.
            </p>
          </Stack>
        );
      case ChangeEmailErrorStates.CANCELLED:
        return (
          <Stack>
            <p style={paragraphStyles}>
              This email change request was cancelled from the user settings page.
            </p>
            <p style={paragraphStyles}>
              Please proceed to{" "}
              <SuitedLink href="/home/user-settings" tooltip="User Settings">
                User Settings
              </SuitedLink>{" "}
              to log in and initiate a new email change.
            </p>
          </Stack>
        );
      default:
        return (
          <Stack>
            <p style={paragraphStyles}>An error has occured with your email change request.</p>
            <p style={paragraphStyles}>
              Please proceed to{" "}
              <SuitedLink href="/home/user-settings" tooltip="User Settings">
                User Settings
              </SuitedLink>{" "}
              to log in and initiate a new email change.
            </p>
          </Stack>
        );
    }
  };

  return (
    <AuthenticationTemplate
      headline="There was a Problem with Your Email Change"
      ctaText="Sign In"
      ctaOnClick={handleClickSignIn}
      children={
        <Stack>
          {errorMessage(error)}
          <p style={paragraphStyles}>
            If you're having difficulty logging in, please try{" "}
            <SuitedLink href="/user-request-password-reset" tooltip="Reset Password">
              resetting your password
            </SuitedLink>
            .
          </p>
          <p style={paragraphStyles}>
            If you cannot log in after resetting your password, please{" "}
            <SuitedLink
              href="mailto:support@wellsuited.com"
              target="_blank"
              tooltip="Email Support"
            >
              let us know.
            </SuitedLink>
          </p>
        </Stack>
      }
    />
  );
};

export default UserEmailChangeError;
