import { useMutation } from "@apollo/client";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useMatch, useNavigate } from "@tanstack/react-router";
import debounce from "lodash/debounce";
import { useCallback, useMemo } from "react";

import { Box, Center, Grid, HStack, Stack } from "@suited/components";

import { InstructionContent } from "suited/components/SuitedAssessment/SuitedAssessmentLanding.style";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { StyledFontAwesomeIcon } from "suited/components/shared/icons/StyledFontAwesomeIcon";
import SuitedCallout from "suited/components/shared/typography/SuitedCallout";
import SuitedCalloutHeadline from "suited/components/shared/typography/SuitedCalloutHeadline";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";
import { LegalCopy } from "suited/components/shared/typography/SuitedLegalCopy";
import { timeouts } from "suited/constants/interaction-constants";
import { routePaths } from "suited/constants/routePaths";

import { DEBOUNCE_WAIT_TIME } from "../../eca/ReasonableAccommodationsModal/ReasonableAccomodationsModal.constants";
import { CREATE_DEFAULT_ASSESSMENTS } from "../../eca/eca.queries";
import { instructions } from "./instructions";

export const LateralInstructionsPage = () => {
  const navigate = useNavigate();
  const match = useMatch({ strict: false });

  const [createAssessment, { loading }] = useMutation(CREATE_DEFAULT_ASSESSMENTS, {
    onCompleted: () => {
      navigate({
        to: routePaths.COGNITIVE_SKILLS_ASSESSMENT_APP,
        search: match.search,
        params: match.params
      });
    }
  });

  const debouncedHandler = useMemo(
    () => debounce(createAssessment, DEBOUNCE_WAIT_TIME),
    [createAssessment]
  );
  const startAssessment = useCallback(() => debouncedHandler(), [debouncedHandler]);

  return (
    <Box padding="xl" overflow="auto">
      <Center>
        <InstructionContent>
          <Stack>
            <Box>
              <Stack space="xl">
                <SuitedCopyHeadline noMargin>Lateral Law Assessment</SuitedCopyHeadline>
                <p>
                  Welcome to the Suited lateral law assessment. Your individual responses to
                  questions or prompts will not be shared with any firm. Instead, the results will
                  be summarized to help firms understand you more holistically.
                </p>
                <SuitedCopySubheadline noMargin>INSTRUCTIONS</SuitedCopySubheadline>
                <Grid space="xl">
                  {instructions.map(({ headline, icon, copy }) => {
                    return (
                      <HStack align="flex-start" key={headline}>
                        <StyledFontAwesomeIcon size="3x" icon={icon as IconProp} />
                        <Stack>
                          <SuitedCalloutHeadline noMargin>{headline}</SuitedCalloutHeadline>
                          <SuitedCallout noMargin>{copy}</SuitedCallout>
                        </Stack>
                      </HStack>
                    );
                  })}
                </Grid>
              </Stack>
            </Box>

            <LegalCopy>
              This assessment is intellectual property created and administered by Suited. By
              proceeding to take this assessment,{" "}
              <strong>you agree to not disclose, redistribute, or reproduce the contents</strong>,
              in whole or in part, without the prior express written consent of Suited.
            </LegalCopy>
            <Box>
              <Center>
                <SuitedButton
                  purpose="primary"
                  disabled={loading}
                  onClick={startAssessment}
                  delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
                  data-analytics-id="lateral-assessment-start"
                >
                  Begin
                </SuitedButton>
              </Center>
            </Box>
          </Stack>
        </InstructionContent>
      </Center>
    </Box>
  );
};
