import { useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "@tanstack/react-router";
import _get from "lodash/get";
import { useEffect, useRef } from "react";
import ReactToolTip from "react-tooltip";
import styled from "styled-components";

import { Box, Flex, Stack } from "@suited/components";

import { UnstyledButton } from "suited/components/shared/buttons/UnstyledButton";
import { PhoneAndTablet } from "suited/components/shared/responsive/Responsive";
import { colors as redesignColors } from "suited/constants/colors";
import { routePaths } from "suited/constants/routePaths";
import { colors, sizes, typography } from "suited/constants/style-constants";
import { AccountTypes } from "suited/constants/user.constants";
import logoSrc from "suited/img/icon-white.svg";
import { isProfileComplete } from "suited/util/userProfile";

import { GET_USER_BOOTSTRAP } from "../GraphQL/Queries";
import { TUserQueryData } from "../GraphQL/types";
import { assessmentStatusTypes } from "../eca/eca.constants";
import { AssessmentIds as ECAAssessmentIds } from "../eca/eca.constants";
import { GET_CANDIDATE_ASSESSMENTS } from "../eca/eca.queries";
import type { GetCandidateAssessmentsQuery } from "../eca/eca.types";
import { AssessmentIds } from "../lateral/lateral.constants";
import NavItem from "./NavItem";
import { NavLabel } from "./NavItem.style";
import {
  FeedbackIcon,
  FeedbackLabelCopy,
  FeedbackLabelHelperText,
  ImgContainer,
  NavItemContainer,
  StyledHeaderImg,
  StyledNavLink,
  StyledSuitedIconButton
} from "./NavigationSidebar.style";

type Props = {
  user: any;
  isSuitedAssessmentCompleted: boolean;
  isProfileComplete: boolean;
  onNavigate?: () => void;
  onClickFeedback?: () => void;
};

const ProfileCompleteBadge = styled.div`
  flex: 0 0 auto;
  position: sticky;
  bottom: ${sizes.gridUnits.times10};
  background-color: ${redesignColors.sunflower};
  text-align: center;
  font-family: ${typography.family.heading};
  padding: 1rem;
  border-top: 1px solid ${colors.tints.times3};
  border-bottom: 1px solid ${colors.shades.times4};
  font-weight: ${typography.weight.heading.medium};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavigationSidebar = (props: Props) => {
  const { user, isSuitedAssessmentCompleted, onNavigate = () => {} } = props;
  const navigate = useNavigate();

  const { loading: isUserBootstrapLoading, data: getUserBootstrapData } =
    useQuery<TUserQueryData>(GET_USER_BOOTSTRAP);
  const {
    data: candidateAssessmentsData,
    loading: isCandidateAssessmentsDataLoading,
    error: candidateAssessmentError
  } = useQuery<GetCandidateAssessmentsQuery>(GET_CANDIDATE_ASSESSMENTS);
  const iconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (iconRef.current) {
      ReactToolTip.show(iconRef.current);
    }
  }, [candidateAssessmentError]);

  const isLoading = isUserBootstrapLoading || isCandidateAssessmentsDataLoading;

  // routes

  const candidateAssessments = candidateAssessmentsData?.GetCandidateAssessments || [];

  const isCandidateAssessmentComplete =
    candidateAssessments?.find((assessment) => assessment.id === ECAAssessmentIds.ECA_ASSESSMENT)
      ?.status === assessmentStatusTypes.COMPLETE;

  const isLateralLawAssessmentComplete =
    candidateAssessments?.find((assessment) => assessment.id === AssessmentIds.LATERAL_LAW)
      ?.status === assessmentStatusTypes.COMPLETE;

  // profile completion data
  const completionData = _get(user, "userProfileCompletion", {});
  const areUserWorkPreferencesCompleted = !!_get(completionData, "completedWorkPreferences");

  const timerMultiplier = getUserBootstrapData?.GetUser?.accommodations?.assessmentTimerMultiplier;
  const ecaNavItemLabel =
    "Cognitive Skills Assessment" +
    (timerMultiplier && timerMultiplier > 1 ? ` (${timerMultiplier}× Time)` : "");

  const profileComplete = isProfileComplete({
    ...completionData,
    isCandidateAssessmentComplete,
    isPsychometricAssessmentComplete: isSuitedAssessmentCompleted
  });

  return (
    <Stack space="none" height="100%">
      <Link to="/" onClick={onNavigate}>
        <Box bg={colors.background.dark.primary} padding="none">
          <Flex justify="space-between">
            <ImgContainer>
              <StyledHeaderImg src={logoSrc} title="Suited" alt="suited logo" />
            </ImgContainer>
            <PhoneAndTablet>
              <Flex justify="center" align="center" alignSelf="center">
                <Box padding="md">
                  <StyledSuitedIconButton
                    iconName="times"
                    iconWeight="regular"
                    onClickButton={onNavigate}
                    tooltip="Close menu"
                    delay={0}
                  />
                </Box>
              </Flex>
            </PhoneAndTablet>
          </Flex>
        </Box>
      </Link>
      <NavItemContainer>
        <Stack space="none">
          <StyledNavLink
            to={routePaths.EDUCATION}
            onClick={onNavigate}
            className="link"
            activeProps={{ className: "selected" }}
            $completed={completionData.completedSchools}
          >
            <NavItem
              isLoading={isLoading}
              $completed={completionData.completedSchools}
              url={routePaths.EDUCATION}
              label="Education"
            />
          </StyledNavLink>
          <StyledNavLink
            to={routePaths.WORK_PREFERENCES}
            onClick={onNavigate}
            className="link"
            activeProps={{ className: "selected" }}
            $completed={areUserWorkPreferencesCompleted}
          >
            <NavItem
              isLoading={isLoading}
              $completed={areUserWorkPreferencesCompleted}
              url={routePaths.WORK_PREFERENCES}
              placeholderCount={2}
              label="Location Preferences"
            />
          </StyledNavLink>
          <StyledNavLink
            to={routePaths.DEMOGRAPHIC_INFO}
            onClick={onNavigate}
            className="link"
            activeProps={{ className: "selected" }}
            $completed={completionData.completedPersonalInformation}
          >
            <NavItem
              isLoading={isLoading}
              $completed={completionData.completedPersonalInformation}
              url={routePaths.DEMOGRAPHIC_INFO}
              placeholderCount={2}
              label="Demographic Information"
            />
          </StyledNavLink>
          <StyledNavLink
            to={routePaths.BEHAVIORAL_ASSESSMENT}
            onClick={onNavigate}
            className="link"
            activeProps={{ className: "selected" }}
            $completed={isSuitedAssessmentCompleted}
          >
            <NavItem
              isLoading={isLoading}
              $completed={isSuitedAssessmentCompleted}
              url={routePaths.BEHAVIORAL_ASSESSMENT}
              placeholderCount={2}
              label="Behavioral Assessment"
            />
          </StyledNavLink>
          {candidateAssessmentError ? (
            <Box>
              <Flex justify="space-between">
                <Box padding="lg">
                  <NavLabel style={{ color: typography.color.status.error }}>
                    Error loading assessments
                  </NavLabel>
                </Box>
                <Flex flex="0 0 72px" justify="center" align="center" alignSelf="stretch">
                  <Box>
                    <div ref={iconRef} data-tip data-for="eca-error-tooltip" data-event="event">
                      <FontAwesomeIcon
                        size="lg"
                        icon={["fas", "exclamation-triangle"]}
                        style={{ color: typography.color.status.error }}
                      />
                    </div>

                    <Box>
                      <ReactToolTip
                        clickable
                        id="eca-error-tooltip"
                        place="right"
                        scrollHide={false}
                        resizeHide={false}
                        backgroundColor={typography.color.status.error}
                        getContent={() => (
                          <Box width="45ch">
                            Try signing out and signing back in to the app. If the issue persists,
                            please reach out to support@wellsuited.com.
                          </Box>
                        )}
                      />
                    </Box>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          ) : (
            <>
              {getUserBootstrapData?.GetUser?.accountType === AccountTypes.LATERAL ? (
                <Box data-testid="assessment-nav-item">
                  <StyledNavLink
                    to={routePaths.LATERAL_ASSESSMENT}
                    onClick={onNavigate}
                    className="link"
                    activeProps={{ className: "selected" }}
                    $completed={isLateralLawAssessmentComplete}
                  >
                    <NavItem
                      isLoading={isLoading}
                      $completed={isLateralLawAssessmentComplete}
                      placeholderCount={3}
                      label="Lateral Law Assessment"
                    />
                  </StyledNavLink>
                </Box>
              ) : (
                <Box data-testid="assessment-nav-item">
                  <StyledNavLink
                    to={routePaths.COGNITIVE_SKILLS_ASSESSMENT}
                    onClick={onNavigate}
                    className="link"
                    activeProps={{ className: "selected" }}
                    $completed={isCandidateAssessmentComplete}
                  >
                    <NavItem
                      isLoading={isLoading}
                      $completed={isCandidateAssessmentComplete}
                      placeholderCount={3}
                      label={ecaNavItemLabel}
                    />
                  </StyledNavLink>
                </Box>
              )}
            </>
          )}

          <Box>
            <StyledNavLink
              to={routePaths.RESUME}
              onClick={onNavigate}
              className="link"
              activeProps={{ className: "selected" }}
              $completed={completionData.completedResume}
            >
              <NavItem
                isLoading={isLoading}
                $completed={completionData.completedResume}
                url={routePaths.RESUME}
                label="Resume"
              />
            </StyledNavLink>
          </Box>
        </Stack>
      </NavItemContainer>
      <Stack space="none">
        {profileComplete && (
          <div
            style={{
              color: redesignColors.white
            }}
          >
            <Box>
              <ProfileCompleteBadge>Profile Complete</ProfileCompleteBadge>
            </Box>
          </div>
        )}
        <UnstyledButton onClick={() => navigate({ to: routePaths.FAQ })}>
          <Box bg={colors.color1.base}>
            <Flex justify="space-between">
              <Box padding="md">
                <FeedbackLabelCopy>
                  <div>Need Help?</div>
                </FeedbackLabelCopy>
                <FeedbackLabelHelperText>
                  <div>Please reach out.</div>
                </FeedbackLabelHelperText>
              </Box>
              <Flex flex="0 0 72px" justify="center" align="center" alignSelf="stretch">
                <FeedbackIcon size="2x" icon={["fal", "comments-alt"]} />
              </Flex>
            </Flex>
          </Box>
        </UnstyledButton>
      </Stack>
    </Stack>
  );
};

export default NavigationSidebar;
