import { createFileRoute } from "@tanstack/react-router";

import UserPasswordReset from "suited/components/PasswordReset/UserPasswordReset";

export const Route = createFileRoute("/user-reset-password")({
  component: UserPasswordReset,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      passwordResetId: search.passwordResetId as string
    };
  }
});
