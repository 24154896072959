import { useEffect } from "react";
import styled from "styled-components";
import { Box, Stack, Center } from "@suited/components";
import { useQuery } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from '@tanstack/react-router';

import {
  AssessmentID,
  GetNextAssessmentSectionQuestionsQuery
} from "suited/components/CandidateHome/eca/eca.types";
import { useGetCandidateAssessments } from "../../eca/eca.hooks";
import { GET_NEXT_ASSESSMENT_SECTION_QUESTIONS } from "../../eca/eca.queries";
import { AssessmentIds } from "../lateral.constants";
import { QuestionSkeleton } from "../QuestionSkeleton/QuestionSkeleton";
import { LateralSubmitButton } from "../LateralSubmitButton/LateralSubmitButton";
import { LateralQuestionsItemGroups } from "../LateralQuestionsItemGroups/LateralQuestionsItemGroups";
import { routePaths } from "suited/constants/routePaths";
import { SectionProgress } from "../SectionProgress/SectionProgress";

export const Content = styled(Box)`
  max-width: 95ch;
  margin-bottom: 3rem;
`;

export const LateralSectionQuestions = () => {
  const { assessments, isGetCandidateAssessmentsLoading } = useGetCandidateAssessments();
  const navigate = useNavigate();

  const assessmentID =
    assessments.find((assessment) => assessment.id === AssessmentIds.LATERAL_LAW)?.id || "";
  const SKELETONS_TO_GENERATE = 10;

  const { loading, data } = useQuery<GetNextAssessmentSectionQuestionsQuery, AssessmentID>(
    GET_NEXT_ASSESSMENT_SECTION_QUESTIONS,
    {
      fetchPolicy: "network-only",
      skip: !assessmentID,
      variables: { assessmentID }
    }
  );

  const isLoading = isGetCandidateAssessmentsLoading || loading;

  const shouldRedirectToLateralLandingPage =
    !loading && data?.GetNextAssessmentSectionQuestions?.id === null;

  useEffect(() => {
    if (shouldRedirectToLateralLandingPage) {
      navigate({ to: routePaths.LATERAL_ASSESSMENT });
    }
  }, [shouldRedirectToLateralLandingPage, navigate]);

  return (
    <>
      {!isLoading ? <SectionProgress sectionData={data} /> : null}
      <Center>
        <Content padding="xxl">
          <Stack space="xxl">
            <Box>
              <Stack space="xxxl">
                {isLoading ? (
                  <Box width="85ch">
                    <Stack space="xxl">
                      {Array(SKELETONS_TO_GENERATE)
                        .fill(null)
                        .map((_, idx) => (
                          <Box key={idx}>
                            <QuestionSkeleton />
                          </Box>
                        ))}
                    </Stack>
                  </Box>
                ) : (
                  <Stack space="xxxl">
                    <LateralQuestionsItemGroups />
                  </Stack>
                )}
              </Stack>
            </Box>
            <Center>
              {isLoading ? (
                <Skeleton style={{ borderRadius: "5px" }} height={48} width={140} />
              ) : (
                <LateralSubmitButton />
              )}
            </Center>
          </Stack>
        </Content>
      </Center>
    </>
  );
};
