import { useSearch } from "@tanstack/react-router";
import * as React from "react";

import { uuidv4 } from "@suited/utils";

import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";

import { setAuthToken } from "../Login";
import UserRegistration from "./UserRegistration.component";
import { IRegistrationState } from "./UserRegistration.types";
import { stateKey } from "./reducers";

const initialRegistrationState: IRegistrationState = {
  industryContext: "",
  invitationCode: "",
  autopilotSessionId: "",
  formKey: uuidv4(),
  utm: {
    source: "",
    campaign: "",
    medium: "",
    content: "",
    brand: "",
    term: ""
  },
  identity: {
    firstname: "",
    lastname: "",
    email: ""
  }
};

const Registration = () => {
  const search = useSearch({ strict: false });

  const dispatch = useAppDispatch();

  const email = useAppSelector((state: any) => state[stateKey].email);

  // Local component state for registration form
  const [state, setState] = React.useState<IRegistrationState>(() => {
    const industryContext = search.industry || "";
    const invitationCode = search.code;
    const autopilotSessionId = search.sessionId;

    const utm = {
      source: search.utm_source || "",
      medium: search.utm_medium || "",
      campaign: search.utm_campaign || "",
      content: search.utm_content || "",
      term: search.utm_term || "",
      brand: search.utm_brand || ""
    };

    const identity = {
      firstname: search.firstname || "",
      lastname: search.lastname || "",
      email: search.email || ""
    };

    return {
      ...initialRegistrationState,
      industryContext,
      invitationCode: invitationCode || "",
      autopilotSessionId: autopilotSessionId || "",
      utm,
      identity
    };
  });

  // Whenever the email changes, reset the form key
  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      formKey: uuidv4()
    }));
  }, [email]);

  // Dispatch the setAuthToken action
  const handleSetAuthToken = (token: string) => {
    dispatch(setAuthToken(token));
  };

  return (
    <UserRegistration
      formKey={state.formKey}
      industryContext={state.industryContext}
      autopilotSessionId={state.autopilotSessionId}
      invitationCode={state.invitationCode}
      utm={state.utm}
      identity={state.identity}
      setAuthToken={handleSetAuthToken} // Pass down the token setter
    />
  );
};

export default Registration;
