export const routePaths = {
  ROOT: "/",
  COGNITIVE_SKILLS_ASSESSMENT: "/assessments/cognitive-skills",
  LATERAL_ASSESSMENT: "/assessments/lateral",
  LATERAL_ASSESSMENT_APP: "/assessments/lateral/app",
  COGNITIVE_SKILLS_ASSESSMENT_APP: "/assessments/cognitive-skills-app",
  USER_SETTINGS: "/user-settings",
  EDUCATION: "/education",
  DEMOGRAPHIC_INFO: "/demographic",
  WORK_PREFERENCES: "/work-preferences",
  REGISTER: "/register",
  FAQ: "/faq",
  LOGIN: "/login",
  EMAIL_CONFIRMED: "/email-confirmed",
  CONNECTION_MANAGER: "/connection-manager",
  REGISTRATION_THANK_YOU: "/registration-thank-you",
  USER_REQUEST_PASSWORD_RESET: "/user-request-password-reset",
  USER_RESET_PASSWORD: "/user-reset-password",
  EMAIL_ALREADY_CONFIRMED: "/email-already-confirmed",
  CHANGE_EMAIL_CONFIRMED: "/change-email-confirmed",
  ADD_SECONDARY_EMAIL_CONFIRMED: "/add-secondary-email-confirmed",
  CHANGE_EMAIL_ERROR: "/change-email-error",
  CHANGE_EMAIL_TOKEN_EXPIRED: "/change-email-token-expired",
  ADD_SECONDARY_EMAIL_TOKEN_EXPIRED: "/add-secondary-email-token-expired",
  CHANGE_EMAIL_TOKEN_INCORRECT: "/change-email-token-incorrect",
  ADD_SECONDARY_EMAIL_TOKEN_INCORRECT: "/add-secondary-email-token-incorrect",
  BEHAVIORAL_ASSESSMENT: "/assessments/behavioral-assessment",
  RESUME: "/resume",
  ASSESSMENT: "/assessment/:assessmentName"
} as const;

export const PUBLIC_ROUTES = [
  routePaths.ROOT,
  routePaths.LOGIN,
  routePaths.REGISTER,
  routePaths.REGISTRATION_THANK_YOU,
  routePaths.EMAIL_CONFIRMED,
  routePaths.USER_REQUEST_PASSWORD_RESET,
  routePaths.USER_RESET_PASSWORD,
  routePaths.CHANGE_EMAIL_CONFIRMED,
  routePaths.ADD_SECONDARY_EMAIL_CONFIRMED,
  routePaths.CHANGE_EMAIL_ERROR,
  routePaths.CHANGE_EMAIL_TOKEN_EXPIRED,
  routePaths.CHANGE_EMAIL_TOKEN_INCORRECT,
  routePaths.ADD_SECONDARY_EMAIL_TOKEN_INCORRECT
] as const;
