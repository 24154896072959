import { Link } from "@tanstack/react-router";
import React from "react";
import styled, { css } from "styled-components";

import { timeouts } from "suited/constants/interaction-constants";
import { colors, sizes, typography } from "suited/constants/style-constants";

interface linkProps {
  color: string | undefined;
  backgroundColor: string | undefined;
  onClick?: any;
}

const linkStyle = css<linkProps>`
  font-family: ${typography.family.body};
  font-size: clamp(0.813rem, 0.203vw + 0.772rem, 1rem);
  line-height: ${typography.leading.fixed.multiLine};
  color: ${(props) => (props.color ? props.color : colors.accent1.base)};
  background-image: linear-gradient(
    to bottom,
    ${(props) =>
        props.backgroundColor || props.color
          ? props.backgroundColor || props.color
          : colors.accent1.base}
      0%,
    ${(props) =>
        props.backgroundColor || props.color
          ? props.backgroundColor || props.color
          : colors.accent1.base}
      100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: ${sizes.gridUnits.divide2} 1px;
  text-decoration: none;
  outline: none;
  transition: background-size ${timeouts.TRANSITION_DELAY}ms linear,
    color ${timeouts.TRANSITION_DELAY}ms linear, background-image ${timeouts.NO_DELAY}ms;
  cursor: pointer;
  &:hover,
  &:focus {
    color: ${(props) => (props.color ? props.color : colors.accent1.base)};
    background-size: ${sizes.gridUnits.divide2} ${sizes.gridUnits.divide2};
    background-image: linear-gradient(
      to bottom,
      ${(props) =>
          props.backgroundColor || props.color
            ? props.backgroundColor || props.color
            : colors.accent1.base}
        0%,
      ${(props) =>
          props.backgroundColor || props.color
            ? props.backgroundColor || props.color
            : colors.accent1.base}
        100%
    );
  }
  &:active {
    color: ${(props) => (props.color ? props.color : colors.accent1.base)};
    background-image: linear-gradient(
      to bottom,
      ${(props) =>
          props.backgroundColor || props.color
            ? props.backgroundColor || props.color
            : colors.accent1.shade1}
        0%,
      ${(props) =>
          props.backgroundColor || props.color
            ? props.backgroundColor || props.color
            : colors.accent1.shade1}
        100%
    );
  }
`;

export const SuitedLinkAEl = styled(
  // eslint-disable-next-line
  ({ backgroundColor, ...rest }) => <a {...rest} />
)<{ backgroundColor } & React.AnchorHTMLAttributes<any>>`
  ${linkStyle};
`;

export const StyledLink = styled(({ backgroundColor, ...rest }) => <Link {...rest} />)<
  { backgroundColor } & React.ComponentProps<typeof Link>
>`
  ${linkStyle};
`;
