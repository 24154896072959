import { createFileRoute } from "@tanstack/react-router";

import UserRegistration from "suited/components/UserRegistration";

type RegisterSearchParams = {
  industry?: string;
  code?: string;
  sessionId?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  utm_brand?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  companyName?: string;
  industryContext?: string;
};

export const Route = createFileRoute("/register")({
  component: UserRegistration,
  validateSearch: (search: Record<string, unknown>): RegisterSearchParams => {
    return {
      industry: search.industry as string,
      code: search.code as string,
      sessionId: search.sessionId as string,
      utm_source: search.utm_source as string,
      utm_medium: search.utm_medium as string,
      utm_campaign: search.utm_campaign as string,
      utm_content: search.utm_content as string,
      utm_term: search.utm_term as string,
      utm_brand: search.utm_brand as string,
      firstname: search.firstname as string,
      lastname: search.lastname as string,
      email: search.email as string,
      companyName: search.companyName as string,
      industryContext: search.industryContext as string
    };
  }
});
