import { useNavigate } from "@tanstack/react-router";
import { useState } from "react";

import AuthenticationTemplate from "suited/components/AuthenticationTemplate/AuthenticationTemplate";

import { IIdentityParams, IUTMParams } from "./UserRegistration.types";
import RegistrationForm from "./components/RegistrationForm";

export interface IUserRegistrationProps {
  industryContext: string;
  autopilotSessionId: string;
  formKey: string;
  invitationCode: string;
  setAuthToken: (token: any) => void;
  utm: IUTMParams;
  identity: IIdentityParams;
}

const UserRegistration = (props: IUserRegistrationProps) => {
  const navigate = useNavigate();

  const [redirect, setRedirect] = useState(false);
  const handleClickSignIn = () => {
    setRedirect(true);
  };

  if (redirect)
    if (props.invitationCode) {
      navigate({ to: `/login?code=${props.invitationCode}&utm_source=direct_invitation` });
    } else {
      navigate({ to: "/login" });
    }

  return (
    <AuthenticationTemplate
      headline="Sign Up for Free"
      ctaText="Sign In"
      ctaOnClick={handleClickSignIn}
      registrationPage
    >
      <RegistrationForm {...props} />
    </AuthenticationTemplate>
  );
};

export default UserRegistration;
