import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { Stack } from "@suited/components";
import { colors } from "suited/constants/style-constants";

import AuthenticationTemplate from "../../../AuthenticationTemplate/AuthenticationTemplate";
import SuitedLink from "../../../shared/buttons/SuitedLink";
import { ChangeEmailErrorStates } from "../common";

interface Props {
  error: string;
}

export const SecondaryEmailChangeError = (props: Props) => {
  const navigate = useNavigate();
  const [navigateToLogin, setNavigateToLogin] = useState<boolean>(false);

  const handleClickSignIn = () => {
    setNavigateToLogin(true);
  };

  if (navigateToLogin) {
    navigate({ to: '/login' });
    return null;
  }

  const { error } = props;

  const paragraphStyles = { color: colors.white };

  const errorMessage = (errorState: string) => {
    switch (errorState) {
      case ChangeEmailErrorStates.TOKENEXPIRED:
        return (
          <Stack>
            <p style={paragraphStyles}>
              The token associated with this secondary email confirmation request has expired.
            </p>
            <p style={paragraphStyles}>
              Please proceed to{" "}
              <SuitedLink href="/home/user-settings" tooltip="User Settings">
                User Settings
              </SuitedLink>{" "}
              to log in and initiate a new secondary email confirmation.
            </p>
          </Stack>
        );
      case ChangeEmailErrorStates.TOKENINCORRECT:
        return (
          <Stack>
            <p style={paragraphStyles}>
              The token associated with this secondary email confirmation request is incorrect.
            </p>
            <p style={paragraphStyles}>
              Please try clicking the link to confirm your new secondary email again. If you copy
              and pasted the link into your browser, please make sure there are no additional
              characters when you paste the URL.
            </p>
          </Stack>
        );
      case ChangeEmailErrorStates.CANCELLED:
        return (
          <Stack>
            <p style={paragraphStyles}>
              This secondary email confirmation request was cancelled from the user settings page.
            </p>
            <p style={paragraphStyles}>
              Please proceed to{" "}
              <SuitedLink href="/home/user-settings" tooltip="User Settings">
                User Settings
              </SuitedLink>{" "}
              to log in and initiate a new secondary email confirmation.
            </p>
          </Stack>
        );
      default:
        return (
          <Stack>
            <p style={paragraphStyles}>
              An error has occurred with your secondary email confirmation request.
            </p>
            <p style={paragraphStyles}>
              Please proceed to{" "}
              <SuitedLink href="/home/user-settings" tooltip="User Settings">
                User Settings
              </SuitedLink>{" "}
              to log in and initiate a new secondary email confirmation.
            </p>
          </Stack>
        );
    }
  };

  return (
    <AuthenticationTemplate
      headline="There was a Problem with Your Secondary Email Confirmation"
      ctaText="Sign In"
      ctaOnClick={handleClickSignIn}
      children={
        <Stack>
          {errorMessage(error)}
          <p style={paragraphStyles}>
            If you're having difficulty logging in, please try{" "}
            <SuitedLink href="/user-request-password-reset" tooltip="Reset Password">
              resetting your password
            </SuitedLink>
            .
          </p>
          <p style={paragraphStyles}>
            If you cannot log in after resetting your password, please{" "}
            <SuitedLink
              href="mailto:support@wellsuited.com"
              target="_blank"
              tooltip="Email Support"
            >
              let us know.
            </SuitedLink>
          </p>
        </Stack>
      }
    />
  );
};
