import styled, { css } from "styled-components";
import { Link } from "@tanstack/react-router";

import { colors, typography } from "suited/constants/style-constants";
import IElementInteractionState from "suited/components/shared/types/IElementInteractionState";

interface ILinkStyleProps {
  color?: string;
  backgroundColor?: string;
}

const linkBaseStyles = css<ILinkStyleProps>`
  font-family: ${typography.family.body};
  font-size: ${typography.sizes.copy.value};
  line-height: 1.3;
  color: ${(props) => (props.color ? props.color : colors.accent1.base)};
  background-image: linear-gradient(
    to bottom,
    ${(props) =>
        props.backgroundColor || props.color
          ? props.backgroundColor || props.color
          : colors.accent1.base}
      0%,
    ${(props) =>
        props.backgroundColor || props.color
          ? props.backgroundColor || props.color
          : colors.accent1.base}
      100%
  );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 4px 1px;
  text-decoration: none;
  padding: 0 4px 0 4px;
  outline: none;
  transition: background-size 200ms linear, color 200ms linear, background-image 0ms;
  cursor: pointer;
`;

const linkHoverAndFocusStyles = css<ILinkStyleProps>`
  color: ${typography.color.light.base};
  background-size: 4px 50px;
  background-image: linear-gradient(
    to bottom,
    ${(props) =>
        props.backgroundColor || props.color
          ? props.backgroundColor || props.color
          : colors.accent1.base}
      0%,
    ${(props) =>
        props.backgroundColor || props.color
          ? props.backgroundColor || props.color
          : colors.accent1.base}
      100%
  );
`;

const linkActiveStyles = css<ILinkStyleProps>`
  color: ${typography.color.light.emphasis};
  background-image: linear-gradient(
    to bottom,
    ${(props) =>
        props.backgroundColor || props.color
          ? props.backgroundColor || props.color
          : colors.accent1.shade1}
      0%,
    ${(props) =>
        props.backgroundColor || props.color
          ? props.backgroundColor || props.color
          : colors.accent1.shade1}
      100%
  );
`;

const linkDisabledStyles = css`
  opacity: 0.5 !important;
  cursor: not-allowed;
`;

export const SuitedLinkAEl = styled(({ backgroundColor, ...rest }) => (
  <a {...rest}>{rest.children}</a>
))<ILinkStyleProps & IElementInteractionState>`
  ${linkBaseStyles}
  ${({ disabled }) =>
    disabled &&
    css`
      ${linkDisabledStyles}
    `}
  ${({ $hovered, $focused, disabled }) =>
    ($hovered || $focused) &&
    !disabled &&
    css`
      ${linkHoverAndFocusStyles}
    `}
  ${({ $active, disabled }) =>
    $active &&
    !disabled &&
    css`
      ${linkActiveStyles}
    `}
`;

export const StyledLink = styled(({ backgroundColor, ...rest }) => <Link {...rest} />)<
  ILinkStyleProps & IElementInteractionState & React.ComponentProps<typeof Link>
>`
  ${linkBaseStyles}
  ${({ disabled }) =>
    disabled &&
    css`
      ${linkDisabledStyles}
    `}
${({ hovered, $focused, disabled }) =>
    (hovered || $focused) &&
    !disabled &&
    css`
      ${linkHoverAndFocusStyles}
    `}
${({ active, disabled }) =>
    active &&
    !disabled &&
    css`
      ${linkActiveStyles}
    `}
`;
