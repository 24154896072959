import { useLocation, useNavigate, useSearch } from "@tanstack/react-router";
import { useRouteContext } from "@tanstack/react-router";
import axios from "axios";
import { useState } from "react";

import { routePaths } from "suited/constants/routePaths";
import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";

import Login from "./Login.component";
import { ACTION_AUTH_LOGIN_SUCCESS, login, stateKey } from "./Login.reducer";
import { UTMParams } from "./Login.types";

interface LoginState {
  email: string;
  password: string;
  invitationCode: string;
  invitationId: string;
  newConfirmationEmailRequested: boolean;
  navigateToRecruiterLogin: boolean;
  utm: UTMParams;
}

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const search = useSearch({ strict: false });
  const routeContext = useRouteContext({ strict: false });

  const authenticating = useAppSelector((state) => state[stateKey].isAuthenticating);
  const error = useAppSelector((state) =>
    state[stateKey].authenticationFailed ? "Invalid email address or password." : ""
  );
  const notConfirmed = useAppSelector((state) => state[stateKey].notConfirmed);
  const notConfirmedEmail = useAppSelector((state) => state[stateKey].notConfirmedEmail);
  const requestId = useAppSelector((state) => state[stateKey].requestId);

  const [loginState, setLoginState] = useState<LoginState>({
    email: "",
    password: "",
    newConfirmationEmailRequested: false,
    invitationCode: search.code || "",
    invitationId: search.id || "",
    navigateToRecruiterLogin: false,
    utm: {
      source: search.utm_source || "",
      medium: search.utm_medium || "",
      campaign: search.utm_campaign || "",
      content: search.utm_content || "",
      term: search.utm_term || "",
      brand: search.utm_brand || ""
    }
  });

  const handleChangeEmail = (value: string) => {
    setLoginState((prev) => ({
      ...prev,
      email: value
    }));
  };

  const handleChangePassword = (value: string) => {
    setLoginState((prev) => ({
      ...prev,
      password: value
    }));
  };

  const onSubmit = () => {
    setLoginState((prev) => ({
      ...prev,
      newConfirmationEmailRequested: false
    }));

    dispatch(
      login(loginState.email, loginState.password, loginState.invitationCode, loginState.utm)
      // @ts-ignore
    ).then((action) => {
      if (action.type === ACTION_AUTH_LOGIN_SUCCESS) {
        const {
          loaderData: { redirectUrl }
        } = routeContext;
        navigate({ to: redirectUrl });
      }
    });
  };

  const handleClickRecruiter = async () => {
    try {
      const response = await axios.get("/company/login");
      const redirectTo = response.data.redirectTo;
      if (redirectTo) {
        window.location.href = redirectTo;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const requestNewConfirmationEmail = async () => {
    try {
      await axios.post(
        "/request-new-confirmation-email",
        {
          email: notConfirmedEmail,
          code: loginState.invitationCode,
          requestId
        },
        {
          headers: { "Content-Type": "application/json; charset=utf-8" }
        }
      );

      navigate({
        to: "/registration-thank-you",
        search: {
          email: notConfirmedEmail,
          requestId
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  // Compute registration URL
  const registrationUrl = location.searchStr
    ? `${routePaths.REGISTER}${location.searchStr}`
    : routePaths.REGISTER;

  return (
    <Login
      email={loginState.email}
      password={loginState.password}
      authenticating={authenticating}
      error={error}
      notConfirmed={notConfirmed}
      invitationCode={loginState.invitationCode}
      invitationId={loginState.invitationId}
      registrationUrl={registrationUrl}
      onChangeEmail={handleChangeEmail}
      onChangePassword={handleChangePassword}
      onSubmit={onSubmit}
      onClickRecruiter={handleClickRecruiter}
      requestNewConfirmationEmail={requestNewConfirmationEmail}
    />
  );
}

export default LoginPage;
