import { useEffect, useCallback } from "react";
import { useMutation } from "@apollo/client";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from '@tanstack/react-router';

import { Box, Center, Stack } from "@suited/components";
import { getNumberOfCompletedSections, setCurrentAssessmentView } from "../lateral.slice";
import { AssessmentIds, AssessmentViews } from "../lateral.constants";
import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";
import { routePaths } from "suited/constants/routePaths";
import { getIsFinalSectionOfAssessment } from "../../eca/eca.utils";
import { useGetCandidateAssessments, useGetNextAssessmentSectionInformation } from "../../eca/eca.hooks";
import { ASSESSMENT_SECTION_CLOSE } from "../../eca/eca.queries";
import { LateralSectionInformationContent } from "./LateralSectionInformation.style";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

export const LateralSectionInformation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const numberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);
  const { assessments } = useGetCandidateAssessments();

  const assessmentID =
    assessments.find((assessment) => assessment.id === AssessmentIds.LATERAL_LAW)?.id || "";
  const {
    loading,
    data,
    id,
    refetch,
    instructions,
    timerDuration,
    timerRemaining,
    isInformational,
    totalSections
  } = useGetNextAssessmentSectionInformation({ fetchPolicy: "network-only" }, assessmentID);

  const isFinalSectionOfAssessment = getIsFinalSectionOfAssessment(
    numberOfCompletedSections,
    totalSections
  );
  const [closeAssessmentSection] = useMutation(ASSESSMENT_SECTION_CLOSE, {
    fetchPolicy: "no-cache",
    variables: {
      id,
      closeType: "MANUAL"
    },
    onCompleted: () => {
      if (isFinalSectionOfAssessment) {
        dispatch(setCurrentAssessmentView(AssessmentViews.END_OF_ASSESSMENT));
      } else {
        refetch();
        dispatch(setCurrentAssessmentView(AssessmentViews.SECTION_INFO));
        // Ensure that when we scroll to new section info page, we scroll to
        // top on new page since we don't want to accomplish this by making
        // the entire modal to re-render.
        const focusModeContainer = document.querySelector("#focus-mode-container");
        focusModeContainer?.scrollTo(0, 0);
      }
    }
  });
  
  const onClickNextButton = useCallback(() => {
    // If section is information only and does not have corresponding questions,
    // go automatically to next section.
    if (isInformational) {
      closeAssessmentSection();
    } else {
      dispatch(setCurrentAssessmentView(AssessmentViews.SECTION_QUESTIONS));
    }
  }, [isInformational, dispatch, closeAssessmentSection]);

  // If query returns data, but id field is null, then the section has already been
  // completed and we should redirect to the Lateral landing page.
  const shouldRedirectToLateralLandingPage =
    !loading && data?.GetNextAssessmentSectionInformation?.id === null;

  const shouldDisplayQuestionsView =
    !loading &&
    timerDuration &&
    timerRemaining !== undefined &&
    timerRemaining < timerDuration &&
    !isInformational;

  useEffect(() => {
    if (shouldRedirectToLateralLandingPage) {
      navigate({ to: routePaths.LATERAL_ASSESSMENT });
      return;
    }
    if (shouldDisplayQuestionsView) {
      onClickNextButton();
    }
  }, [shouldRedirectToLateralLandingPage, navigate, shouldDisplayQuestionsView, onClickNextButton]);

  return (
    <Box padding="xxl" overflow="auto">
      <Center>
        <LateralSectionInformationContent>
          <Stack space="xl">
            <SuitedCopyHeadline noMargin>
              {loading ? <Skeleton width={525} /> : "Section Instructions"}
            </SuitedCopyHeadline>
            <Box>
              <Stack space="lg">
                {loading ? (
                  <Stack space="lg">
                    <p>
                      <Skeleton count={3} containerTestId="react-loading-skeleton" />
                    </p>
                    <p>
                      <Skeleton count={3} />
                    </p>
                    <p>
                      <Skeleton count={5} />
                    </p>
                  </Stack>
                ) : (
                  <>
                    {instructions?.map((instructionParagraphCopy) => (
                      <p key={instructionParagraphCopy}>{instructionParagraphCopy}</p>
                    ))}
                  </>
                )}
              </Stack>
            </Box>
            <Box>
              <Center>
                <SuitedButton
                  purpose="primary"
                  type="button"
                  data-analytics-id="eca-section-start"
                  onClick={onClickNextButton}
                >
                  Next
                </SuitedButton>
              </Center>
            </Box>
          </Stack>
        </LateralSectionInformationContent>
      </Center>
    </Box>
  );
};
