import axios from "axios";
import { RouteValues } from "shared/routes/routes.types";

import { PUBLIC_ROUTES } from "suited/constants/routePaths";

import { createCookie, eraseCookie, readCookie } from "./utils";

const PROFILE_KEY = "profile";
const TOKEN_KEY = "id_token";
const USER_ID_KEY = "id_c";

// Axios interceptors
// TODO: use captive instance for same-server backend requests, which allow interceptors with auth as-needed rather than checking URL to decide.
// see: https://github.com/mzabriskie/axios#custom-instance-defaults
axios.interceptors.request.use((config) => {
  let auth = getAuthToken();
  if (auth) {
    config.headers["Authorization"] = `Bearer ${auth}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.status === 401 && !isPublicRoute(window.location.pathname as RouteValues)) {
      deleteStoredCredentials();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export function storeAuthToken(token) {
  return createCookie(TOKEN_KEY, token, 24 * 7);
}

export function getAuthToken() {
  return readCookie(TOKEN_KEY);
}

export function storeUserId(userId) {
  return createCookie(USER_ID_KEY, userId, 24 * 7);
}

export function getUserId() {
  return readCookie(USER_ID_KEY);
}

export function getUserProfile() {
  return readCookie(PROFILE_KEY);
}

export function deleteStoredCredentials() {
  // remove stored credentials
  eraseCookie(PROFILE_KEY);
  eraseCookie(TOKEN_KEY);
  eraseCookie(USER_ID_KEY);
}

export const isPublicRoute = (route: RouteValues) =>
  PUBLIC_ROUTES.some((publicRoute) => publicRoute === route);
