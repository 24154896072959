import { createFileRoute } from '@tanstack/react-router'

import UserEmailChangeError from 'suited/components/CandidateHome/UserSettings/UserEmailChange/UserEmailChangeError'
import { ChangeEmailErrorStates } from 'suited/components/CandidateHome/UserSettings/common'

export const Route = createFileRoute('/change-email-token-expired')({
  component: () => (
    <UserEmailChangeError error={ChangeEmailErrorStates.TOKENEXPIRED} />
  ),
})
