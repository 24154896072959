import { createFileRoute } from '@tanstack/react-router'

import { SecondaryEmailChangeError } from 'suited/components/CandidateHome/UserSettings/UserEmailChange/UserAddSecondaryEmailConfirmed'
import { ChangeEmailErrorStates } from 'suited/components/CandidateHome/UserSettings/common'

export const Route = createFileRoute('/add-secondary-email-token-incorrect')({
  component: () => (
    <SecondaryEmailChangeError error={ChangeEmailErrorStates.TOKENEXPIRED} />
  ),
})
