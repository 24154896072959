import { useMutation } from "@apollo/client";
import { useNavigate } from "@tanstack/react-router";
import { useCallback, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

import { Box, Center, Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import { Divider } from "suited/components/shared/layout/Divider";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import { routePaths } from "suited/constants/routePaths";
import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";

import { QuestionSkeleton } from "../QuestionSkeleton/QuestionSkeleton";
import { SampleItemGroupsList } from "../SampleItemGroupsList/SampleItemGroupsList";
import { TimedSectionInstructionsCopy } from "../TimedSectionInstructionsCopy/TimedSectionInstructionsCopy";
import { UntimedSectionInstructionsCopy } from "../UntimedSectionInstructionsCopy/UntimedSectionInstructionsCopy";
import { assessmentViews } from "../eca.constants";
import { useGetNextAssessmentSectionInformation } from "../eca.hooks";
import { ASSESSMENT_SECTION_CLOSE } from "../eca.queries";
import { getNumberOfCompletedSections, setCurrentAssessmentView } from "../eca.slice";
import { getIsFinalSectionOfAssessment, getTimeAllotedToSectionCopy } from "../eca.utils";
import { SectionInformationContent } from "./SectionInformationPage.style";

export const SectionInformationPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    loading,
    data,
    id,
    refetch,
    instructions,
    timerDuration,
    timerRemaining,
    isInformational,
    totalSections
  } = useGetNextAssessmentSectionInformation({ fetchPolicy: "network-only" });
  const numberOfCompletedSections = useAppSelector(getNumberOfCompletedSections);

  const isFinalSectionOfAssessment = getIsFinalSectionOfAssessment(
    numberOfCompletedSections,
    totalSections
  );
  const [closeAssessmentSection] = useMutation(ASSESSMENT_SECTION_CLOSE, {
    fetchPolicy: "no-cache",
    variables: {
      id,
      closeType: "MANUAL"
    },
    onCompleted: () => {
      if (isFinalSectionOfAssessment) {
        dispatch(setCurrentAssessmentView(assessmentViews.END_OF_ASSESSMENT));
      } else {
        refetch();
        dispatch(setCurrentAssessmentView(assessmentViews.SECTION_INFO));
        // Ensure that when we scroll to new section info page, we scroll to
        // top on new page since we don't want to accomplish this by making
        // the entire modal to re-render.
        const focusModeContainer = document.querySelector("#focus-mode-container");
        focusModeContainer?.scrollTo(0, 0);
      }
    }
  });
  const onClickNextButton = useCallback(() => {
    // If section is information only and does not have corresponding questions,
    // go automatically to next section.
    if (isInformational) {
      closeAssessmentSection();
    } else {
      dispatch(setCurrentAssessmentView(assessmentViews.SECTION_QUESTIONS));
    }
  }, [isInformational, dispatch, closeAssessmentSection]);

  // If query returns data, but id field is null, then the section has already been
  // completed and we should redirect to the ECA landing page.
  const shouldRedirectToECALandingPage =
    !loading && data?.GetNextAssessmentSectionInformation?.id === null;

  const shouldDisplayQuestionsView =
    !loading &&
    timerDuration &&
    timerRemaining !== undefined &&
    timerRemaining < timerDuration &&
    !isInformational;

  // Redirect to ECA landing page if the user closes out of the modal via
  // back/forward buttons in the browser or typing in the assessment route
  // manually when the ECA is already completed. This prevents users from
  // getting into an invalid UI state.
  useEffect(() => {
    if (shouldRedirectToECALandingPage) {
      navigate({ to: routePaths.COGNITIVE_SKILLS_ASSESSMENT });
      return;
    }
    if (shouldDisplayQuestionsView) {
      onClickNextButton();
      return;
    }
  }, [shouldRedirectToECALandingPage, navigate, shouldDisplayQuestionsView, onClickNextButton]);

  const timeAllotedToSectionCopy = getTimeAllotedToSectionCopy(timerDuration);

  return (
    <Box padding="xxl" overflow="auto">
      <Center>
        <SectionInformationContent>
          <Stack space="xl">
            <SuitedCopyHeadline noMargin>
              {loading ? <Skeleton width={525} /> : "Section Instructions"}
            </SuitedCopyHeadline>
            <Box>
              <Stack space="lg">
                {loading ? (
                  <Skeleton width={680} height={108} />
                ) : (
                  <Box bg="#d3e0e2" padding="sm" borderWidth="1px">
                    <p>
                      <strong>
                        This page is untimed. You may take as much time on this page as you would
                        like before continuing, including pausing or closing your browser. Once you
                        click the "Next" button below, the timer will start and you will not be able
                        to pause again until the section is completed.
                      </strong>
                    </p>
                  </Box>
                )}

                {loading ? (
                  <Stack space="lg">
                    <p>
                      <Skeleton count={3} containerTestId="react-loading-skeleton" />
                    </p>
                    <p>
                      <Skeleton count={3} />
                    </p>
                    <p>
                      <Skeleton count={5} />
                    </p>
                  </Stack>
                ) : (
                  <>
                    {instructions?.map((instructionParagraphCopy) => (
                      <p key={instructionParagraphCopy}>{instructionParagraphCopy}</p>
                    ))}
                  </>
                )}
              </Stack>
            </Box>
            <Divider />
            <Box>{loading ? <QuestionSkeleton /> : <SampleItemGroupsList />}</Box>
            <Box>
              <Stack space="lg">
                {loading ? (
                  <>
                    <p>
                      <Skeleton count={2} />
                    </p>
                    <p>
                      <Skeleton />
                    </p>
                  </>
                ) : (
                  <>
                    {timerDuration ? (
                      <TimedSectionInstructionsCopy timeAlloted={timeAllotedToSectionCopy} />
                    ) : (
                      <UntimedSectionInstructionsCopy />
                    )}
                  </>
                )}
              </Stack>
            </Box>
            <Box>
              <Center>
                <SuitedButton
                  purpose="primary"
                  type="button"
                  data-analytics-id="eca-section-start"
                  onClick={onClickNextButton}
                >
                  Next
                </SuitedButton>
              </Center>
            </Box>
          </Stack>
        </SectionInformationContent>
      </Center>
    </Box>
  );
};
