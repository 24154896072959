import { createFileRoute } from "@tanstack/react-router";

import Login from "suited/components/Login";

type LoginSearchParams = {
  search: {
    redirect?: string;
  };
};

export const Route = createFileRoute("/login")({
  component: Login,
  beforeLoad: ({ search }: LoginSearchParams) => {
    return { loaderData: { redirectUrl: search.redirect || "/" } };
  }
});
