import { useState } from "react";

import AuthenticationTemplate from "suited/components/AuthenticationTemplate/AuthenticationTemplate";
import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";
import SuitedLink from "suited/components/shared/buttons/SuitedLink";
import { SuitedShowPasswordInput } from "suited/components/shared/inputs/SuitedShowPasswordInput";
import ButtonRowCentered from "suited/components/shared/layout/ButtonRowCentered";
import { debounce, timeouts } from "suited/constants/interaction-constants";

import {
  ErrorMessage,
  ForgotPasswordLink,
  LoginFormWrapper,
  RegisterLink,
  RequestNewLink,
  StyledSuitedValidatedTextInput
} from "./Login.style";

type Props = {
  email: string;
  password: string;
  authenticating: boolean;
  error: string;
  notConfirmed: boolean;
  invitationCode: string;
  invitationId: any;
  registrationUrl: string;
  onChangeEmail: (value: any, targetName: string) => void;
  onChangePassword: (value: any, targetName: string) => void;
  onSubmit: () => void;
  onClickRecruiter: () => void;
  requestNewConfirmationEmail(): void;
};

const Login = (props: Props) => {
  const {
    email,
    onChangeEmail,
    password,
    onChangePassword,
    authenticating,
    onSubmit: submit,
    error,
    notConfirmed,
    invitationId,
    requestNewConfirmationEmail,
    registrationUrl
  } = props;

  const [passwordInvalid, setPasswordInvalid] = useState<boolean>(false);

  const defaultSubmitHandler = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
  };

  const isInputInvalid = (error) => {
    // managed when server error, unmanaged otherwise
    return error ? true : undefined;
  };

  const getErrorMessage = (error, value) => {
    if (error) return "Please try again.";
    return value === "" ? "This can't be empty." : "";
  };

  const handleClickRecruiter = () => {
    props.onClickRecruiter();
  };

  const handlePasswordChange = (value) => {
    if (value !== "") {
      setPasswordInvalid(false);
    }
    onChangePassword(value, "password");
  };

  const handlePasswordBlur = () => {
    if (password === "") {
      setPasswordInvalid(true);
    } else {
      setPasswordInvalid(false);
    }
  };

  const hasInvitationCode = props.invitationCode.length > 0;
  const preContentCopy = hasInvitationCode && (
    <>
      Sign in to accept an invitation and make your profile available to the company that linked you
      here.
    </>
  );

  return (
    <AuthenticationTemplate
      headline="Candidate Sign In"
      ctaText="Recruiter Sign In"
      preContentCopy={preContentCopy}
      ctaOnClick={handleClickRecruiter}
      children={
        <LoginFormWrapper onSubmit={authenticating ? defaultSubmitHandler : submit}>
          <StyledSuitedValidatedTextInput
            autoFocus
            name="email"
            value={email}
            onInputChange={onChangeEmail}
            label="Email"
            type="email"
            autoComplete="email"
            required={true}
            invalid={isInputInvalid(error)}
            error={getErrorMessage(error, email)}
            debounceTime={debounce.validation}
            overDark={true}
          />
          <SuitedShowPasswordInput
            name="password"
            value={password}
            onInputChange={handlePasswordChange}
            label="Password"
            autoComplete="current-password"
            required={true}
            invalid={isInputInvalid(error) || passwordInvalid}
            error={getErrorMessage(error, password)}
            debounceTime={debounce.validation}
            overDark={true}
            onInputBlur={handlePasswordBlur}
          />
          <ErrorMessage
            show={error ? true : false}
            error="Email or password doesn't match our records."
          />
          {notConfirmed ? (
            <div>
              <ErrorMessage
                show={notConfirmed ? true : false}
                error="You have yet to confirm your email address. Please check your email or spam folder for a confirmation email from Suited."
              />
              <RequestNewLink>
                <SuitedLink
                  onClick={requestNewConfirmationEmail}
                  tooltip="Resend confirmation email"
                >
                  Click for a new confirmation email.
                </SuitedLink>
              </RequestNewLink>
            </div>
          ) : null}
          <ButtonRowCentered>
            <SuitedButton
              purpose="primary"
              overDark={true}
              onClick={submit}
              type="submit"
              disabled={!(email && password) || authenticating}
              title="Sign In"
              delay={timeouts.BUTTON_CLICK_ANIMATION_DURATION}
            >
              Sign In
            </SuitedButton>
          </ButtonRowCentered>
          <RegisterLink>
            {invitationId ? (
              <div />
            ) : (
              <SuitedLink to={registrationUrl} tooltip="Register Here">
                Not a Suited user? Register here!
              </SuitedLink>
            )}
          </RegisterLink>
          <ForgotPasswordLink>
            <SuitedLink to="/user-request-password-reset" tooltip="Reset Password">
              Forgot Password?
            </SuitedLink>
          </ForgotPasswordLink>
        </LoginFormWrapper>
      }
    />
  );
};

export default Login;
