import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { Grid, HStack, Stack } from "@suited/components";

import { StyledFontAwesomeIcon } from "suited/components/shared/icons/StyledFontAwesomeIcon";
import SuitedCallout from "suited/components/shared/typography/SuitedCallout";
import SuitedCalloutHeadline from "suited/components/shared/typography/SuitedCalloutHeadline";
import SuitedCopyHeadline from "suited/components/shared/typography/SuitedCopyHeadline";
import SuitedCopySubheadline from "suited/components/shared/typography/SuitedCopySubheadline";

import { instructions } from "./content/instructions";

export const Instructions = () => {
  return (
    <Stack space="xl">
      <SuitedCopyHeadline noMargin>Behavioral Assessment</SuitedCopyHeadline>
      <SuitedCopySubheadline noMargin>INSTRUCTIONS</SuitedCopySubheadline>
      <Grid space="xl">
        {instructions.map(({ headline, icon, copy }) => {
          return (
            <HStack align="flex-start" key={headline}>
              <StyledFontAwesomeIcon size="3x" icon={icon as IconProp} />
              <Stack>
                <SuitedCalloutHeadline noMargin>{headline}</SuitedCalloutHeadline>
                <SuitedCallout noMargin>{copy}</SuitedCallout>
              </Stack>
            </HStack>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default Instructions;
