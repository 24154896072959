import { useSearch } from "@tanstack/react-router";
import { useEffect } from "react";

import { FeatureFlagNav } from "@suited/components";
import { shallowEqual } from "@suited/utils";

import { useAppDispatch, useAppSelector } from "suited/util/hooks/reduxHooks";

import {
  FEATURE_FLAG_KEY,
  FEATURE_FLAG_URL_PARAM_NAME,
  FEATURE_FLAG_VISIBILITY_KEY
} from "./FeatureFlags.constants";
import {
  getFeatureFlagConfig,
  getIsFeatureFlagNavVisible,
  hideFeatureFlagNav,
  setFeatureFlags,
  showFeatureFlagNav
} from "./FeatureFlags.slice";

export const FeatureFlags = () => {
  const features = useAppSelector(getFeatureFlagConfig);
  const isFeatureFlagNavVisible = useAppSelector(getIsFeatureFlagNavVisible);

  const dispatch = useAppDispatch();
  const search = useSearch({ strict: false });

  // Extract the specific URL parameter you're interested in
  const featureFlagUrlParamSetting = search[FEATURE_FLAG_URL_PARAM_NAME];

  useEffect(() => {
    if (featureFlagUrlParamSetting == null) {
      return;
    }

    if (featureFlagUrlParamSetting === "false") {
      dispatch(hideFeatureFlagNav());
      dispatch(setFeatureFlags(features));
      localStorage.removeItem(FEATURE_FLAG_VISIBILITY_KEY);
      localStorage.removeItem(FEATURE_FLAG_KEY);
      return;
    }

    if (featureFlagUrlParamSetting === "true" || isFeatureFlagNavVisible) {
      dispatch(showFeatureFlagNav());
      localStorage.setItem(FEATURE_FLAG_VISIBILITY_KEY, "true");

      const localStorageFeatureFlagConfig = localStorage.getItem(FEATURE_FLAG_KEY);
      const parsedLocalStorageFeatureFlagConfig =
        (localStorageFeatureFlagConfig && JSON.parse(localStorageFeatureFlagConfig)) || {};
      const isFeatureFlagConfigSavedInLocalStorage = localStorage.getItem(FEATURE_FLAG_KEY);
      const haveFeatureFlagsChanged = !shallowEqual(features, parsedLocalStorageFeatureFlagConfig);

      if (haveFeatureFlagsChanged) {
        const preSavedFeatureFlagConfig = Object.entries(
          parsedLocalStorageFeatureFlagConfig
        ).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        const updatedFeatureFlagConfig = isFeatureFlagConfigSavedInLocalStorage
          ? preSavedFeatureFlagConfig
          : features;

        dispatch(setFeatureFlags(updatedFeatureFlagConfig));
        localStorage.setItem(FEATURE_FLAG_KEY, JSON.stringify(updatedFeatureFlagConfig));
      }
    }
  }, [dispatch, isFeatureFlagNavVisible, featureFlagUrlParamSetting, features]);

  return isFeatureFlagNavVisible ? (
    <FeatureFlagNav
      features={features}
      onSubmit={(values) => {
        dispatch(setFeatureFlags(values));
        localStorage.setItem(FEATURE_FLAG_KEY, JSON.stringify(values));
      }}
    />
  ) : null;
};
