import { useApolloClient, useQuery } from "@apollo/client";
import { useLocation, useNavigate } from "@tanstack/react-router";
import React from "react";
import { useDispatch } from "react-redux";

import { SuitedToolTip } from "suited/components/shared/layout/SuitedToolTip";
import { routePaths } from "suited/constants/routePaths";

import { GET_PENDING_INVITES } from "../GraphQL/queries";
import {
  ButtonContainerEl,
  Notification,
  NotificationContainer,
  StyledSuitedIconButton
} from "./NavButtons.style";

export const NavButtons = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { data } = useQuery(GET_PENDING_INVITES, {
    variables: { status: "PENDING" }
  });

  const numPendingInvites: number = data?.GetCandidateInvitations.length || 0;

  const handleUserSettingsClick = () => {
    navigate({ to: routePaths.USER_SETTINGS });
  };

  const handleConnectionsClick = () => {
    navigate({ to: routePaths.CONNECTION_MANAGER });
  };

  const handleLogoutClick = async () => {
    dispatch({ type: "ACTION_AUTH_LOGOUT" });

    await client.clearStore();

    navigate({
      to: routePaths.LOGIN,
      search: {
        redirect: location.pathname
      }
    });
  };

  return (
    <ButtonContainerEl>
      <StyledSuitedIconButton
        tooltip={"user settings"}
        iconName={"user-cog"}
        onClick={handleUserSettingsClick}
        data-tip
        data-for="user-settings"
      />
      <SuitedToolTip id="user-settings" type="dark" effect="solid" place="bottom">
        User Settings
      </SuitedToolTip>
      {numPendingInvites > 0 ? (
        <NotificationContainer>
          <StyledSuitedIconButton
            tooltip={`Invitations and Associations, you have ${numPendingInvites} pending invitations.`}
            iconName={"envelope"}
            onClick={handleConnectionsClick}
            data-tip
            data-for="notifications-pending"
            data-analytics-id="company-invitations"
          />
          <Notification>{numPendingInvites}</Notification>
          <SuitedToolTip id="notifications-pending" type="dark" effect="solid" place="bottom">
            {numPendingInvites} Pending Invitations
          </SuitedToolTip>
        </NotificationContainer>
      ) : (
        <React.Fragment>
          <StyledSuitedIconButton
            tooltip={"Invitations and Associations, you have zero pending invitations."}
            iconName={"envelope-open"}
            onClick={handleConnectionsClick}
            data-tip
            data-for="notifications-empty"
          />
          <SuitedToolTip id="notifications-empty" type="dark" effect="solid" place="bottom">
            Zero Pending Invitations
          </SuitedToolTip>
        </React.Fragment>
      )}
      <StyledSuitedIconButton
        tooltip={"logout"}
        iconName={"sign-out-alt"}
        onClick={handleLogoutClick}
        data-tip
        data-for="logout"
      />
      <SuitedToolTip id="logout" type="dark" effect="solid" place="bottom">
        Logout
      </SuitedToolTip>
    </ButtonContainerEl>
  );
};

export default NavButtons;
