import { createFileRoute } from '@tanstack/react-router'
import { ChangeEmailErrorStates } from 'suited/components/CandidateHome/UserSettings/common'

import { SecondaryEmailChangeError } from 'suited/components/CandidateHome/UserSettings/UserEmailChange/UserAddSecondaryEmailConfirmed'

export const Route = createFileRoute('/add-secondary-email-error')({
  component: () => (
    <SecondaryEmailChangeError error={ChangeEmailErrorStates.GENERIC} />
  ),
})
