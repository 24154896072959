import { useNavigate, useSearch } from "@tanstack/react-router";
import axios from "axios";
import React, { useState } from "react";

import { Stack } from "@suited/components";

import { SuitedButton } from "suited/components/shared/buttons/SuitedButton";

import AuthenticationTemplate from "../AuthenticationTemplate/AuthenticationTemplate";
import SuitedLink from "../shared/buttons/SuitedLink";
import ButtonRowCentered from "../shared/layout/ButtonRowCentered";
import {
  ConfirmationText,
  Error,
  StyledButtonRowCentered,
  ThankYouRow
} from "./RegistrationThankYou.style";

const RegistrationThankYou = () => {
  const [newConfirmationEmailRequested, setNewConfirmationEmailRequested] =
    useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const navigate = useNavigate();
  const search = useSearch({ strict: false });

  const { requestId, email, code: invitationCode } = search;

  const onClickResendConfirmationEmail = () => {
    const body = invitationCode ? { email, invitationCode, requestId } : { email, requestId };
    axios
      .post("/request-new-confirmation-email", body, {
        headers: { "Content-Type": "application/json; charset=utf-8" }
      })
      .then(() => {
        setNewConfirmationEmailRequested(true);
      })
      .catch((err) => {
        if (err.response.data.message === "EMAIL_ALREADY_CONFIRMED") {
          setError("You have already confirmed your email address.");
          console.error(err);
        }
      });
  };

  const handleClickSignIn = () => {
    navigate({ to: "/login" });
  };

  return (
    <AuthenticationTemplate
      headline="Thank you for signing up for Suited."
      ctaText="Sign In"
      ctaOnClick={handleClickSignIn}
      children={
        <Stack>
          <ConfirmationText>
            You should receive a confirmation email in your inbox shortly. To access the
            application, please verify your email address through the link provided. If you don't
            receive the email, click the button below to request a new one.
          </ConfirmationText>
          {newConfirmationEmailRequested ? (
            <ConfirmationText>You should get another confirmation email soon.</ConfirmationText>
          ) : (
            <React.Fragment>
              <ButtonRowCentered>
                <SuitedButton
                  purpose="primary"
                  onClick={onClickResendConfirmationEmail}
                  title="Resend Confirmation Email"
                >
                  Resend Confirmation Email
                </SuitedButton>
              </ButtonRowCentered>
              <StyledButtonRowCentered>
                <SuitedLink to="/login" tooltip="Log in">
                  Sign in
                </SuitedLink>
              </StyledButtonRowCentered>
            </React.Fragment>
          )}
          {error ? (
            <ThankYouRow>
              <Error>{error}</Error>
              <SuitedLink to="/login" tooltip="Log in">
                Please sign in.
              </SuitedLink>
            </ThankYouRow>
          ) : (
            <div />
          )}
        </Stack>
      }
    />
  );
};

export default RegistrationThankYou;
