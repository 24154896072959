import { ApolloError, useQuery } from "@apollo/client";
import { Outlet, useNavigate } from "@tanstack/react-router";
import _get from "lodash/get";
import has from "lodash/has";
import React, { useEffect, useState } from "react";
import { pushRotate as BurgerMenu } from "react-burger-menu";

import CandidateConfig, {
  TCandidateConfig,
  defaultConfig
} from "suited/components/CandidateConfig";
import Header from "suited/components/shared/user/Header";
import { burgerMenuStyles } from "suited/constants/style-burger-menu";
import { deleteStoredCredentials } from "suited/util/authUtil";
import { useInitialMountEffect, usePrevious } from "suited/util/hooks";
import { useAppSelector } from "suited/util/hooks/reduxHooks";

import {
  defaultCandidateId,
  defaultIndustryContext
} from "../CandidateConfig/CandidateConfig.constants";
import { getIsFeatureFlagEnabled } from "../FeatureFlags/FeatureFlags.slice";
import SuitedNavigationHeader from "../shared/layout/SuitedNavigationHeader";
import { LaptopAndAbove, PhoneAndTablet } from "../shared/responsive/Responsive";
import { AssessmentContextProvider } from "./Assessment/Context/AssessmentContext";
import {
  Container,
  HeaderContainer,
  HomeContainer,
  HomeMainContent,
  HomeTabContainer
} from "./CandidateHome.style";
import { GET_USER_BOOTSTRAP } from "./GraphQL/Queries";
import { TUserQueryData } from "./GraphQL/types";
import NavigationSidebar from "./Navigation/NavigationSidebar";
import { useProfile } from "./ProfileCompleteProvider";
import { AccountTypeSettingsModal } from "./accountTypeSettings/AccountTypeSettingsModal/AccountTypeSettingsModal";
import { UserAdditionalInfoOverlay } from "./userAdditionalInfo/UserAdditionalInfoOverlay";

export const CandidateHome = () => {
  const [config, setConfig] = useState<TCandidateConfig>(defaultConfig);

  const navigate = useNavigate();

  const { data } = useQuery<TUserQueryData>(GET_USER_BOOTSTRAP, {
    onError: (error: ApolloError) => {
      if (error?.networkError) {
        deleteStoredCredentials();
        navigate({ to: "/login" });
      }
    }
  });
  const [sidePanelOpen, setSidePanelOpen] = useState<boolean>(false);
  const lateralEnabled = useAppSelector(getIsFeatureFlagEnabled("LATERAL"));

  const { profileState, profileDidLoad, profileWasUpdated } = useProfile();

  useInitialMountEffect(profileDidLoad);

  const prevData = usePrevious(data);
  useEffect(() => {
    if (prevData !== data) {
      profileWasUpdated();
    }
  }, [data, prevData, profileWasUpdated]);

  const toggleSidePanel = () => {
    setSidePanelOpen(!sidePanelOpen);
  };

  const handleHamburgerMenuClick = () => {
    toggleSidePanel();
    const activeElement = document.activeElement as HTMLElement;
    activeElement && activeElement.blur();
  };

  const handleClickFeedbackLink = () => {
    const widget = (window as any).FrontChat;
    if (widget) widget("show");
  };

  const isSuitedAssessmentCompleted =
    _get(data, "GetUserSurveyAnswers.status", {}) === "complete" ? true : false;

  useEffect(() => {
    if (data?.GetUser) {
      setConfig({
        ...defaultConfig,
        candidateId: data.GetUser.id || defaultCandidateId,
        industryContext: data.GetUser.industryContext?.id
          ? data.GetUser.industryContext
          : defaultIndustryContext,
        hasSecondaryContactInfo: data.GetUser?.hasSecondaryContactInfo
      });
    }
  }, [data]);

  const isProfileComplete = profileState.complete;

  return (
    <CandidateConfig config={config}>
      <AssessmentContextProvider>
        <HomeContainer>
          <HomeMainContent>
            <HomeTabContainer sidePanelOpen={sidePanelOpen}>
              <Container id="outer-container">
                <PhoneAndTablet>
                  {(isPhoneOrTablet) =>
                    isPhoneOrTablet ? (
                      <React.Fragment>
                        <BurgerMenu
                          isOpen={sidePanelOpen}
                          onStateChange={(state) => setSidePanelOpen(state.isOpen)}
                          styles={burgerMenuStyles}
                          width="17rem"
                          pageWrapId="page-wrap"
                          outerContainerId="outer-container"
                          customBurgerIcon={false}
                          customCrossIcon={false}
                        >
                          <NavigationSidebar
                            user={_get(data, "GetUser")}
                            isSuitedAssessmentCompleted={isSuitedAssessmentCompleted}
                            isProfileComplete={isProfileComplete}
                            onClickFeedback={handleClickFeedbackLink}
                            onNavigate={toggleSidePanel}
                          />
                        </BurgerMenu>
                      </React.Fragment>
                    ) : (
                      <NavigationSidebar
                        user={_get(data, "GetUser")}
                        isSuitedAssessmentCompleted={isSuitedAssessmentCompleted}
                        isProfileComplete={isProfileComplete}
                        onClickFeedback={handleClickFeedbackLink}
                        onNavigate={toggleSidePanel}
                      />
                    )
                  }
                </PhoneAndTablet>

                <HeaderContainer id="page-wrap">
                  <SuitedNavigationHeader onMenuClick={handleHamburgerMenuClick} />
                  <LaptopAndAbove>
                    <Header />
                  </LaptopAndAbove>
                  {has(config, "hasSecondaryContactInfo") &&
                  config?.hasSecondaryContactInfo === false ? (
                    <UserAdditionalInfoOverlay />
                  ) : null}
                  <Outlet />
                </HeaderContainer>
              </Container>
            </HomeTabContainer>
          </HomeMainContent>
        </HomeContainer>
        {lateralEnabled ? <AccountTypeSettingsModal /> : null}
      </AssessmentContextProvider>
    </CandidateConfig>
  );
};
